import { isClient } from '@mop/shared/utils/util';
import { securedWrap } from '@mop/shared/utils/securedWrap';
import { ssrCache } from '@/api/utils/ssrCache';
import type { CustomerGroup } from '@/types/customer';
import type { ProductModel } from '@/types/product';
import type { CartDiscountListModel, CartDiscountResponseData, QueryCartDiscountParams } from '@/types/cartDiscount';
import { cartDiscountListModel } from '@/models';

type DiscountComposableStorage = {
  cartDiscountListRef: Ref<CartDiscountListModel>;
};

export default function useMopCartDiscounts() {
  const nuxtApp = useNuxtApp();
  const storage = initStorage<DiscountComposableStorage>('useMopCartDiscounts');
  const cacheId = `cart-discounts-${nuxtApp.$mopI18n.locale}`;
  const redisCache = ssrCache();
  const responseRef = useMopSSR<CartDiscountResponseData | null>(cacheId, null);
  const discountListRef =
    storage.get('cartDiscountListRef') ??
    storage.saveAndGet('cartDiscountListRef', ref(cartDiscountListModel(responseRef.value)));

  /**
   * Called on app init.
   * Do not init elsewhere.
   */
  async function initAvailableCartDiscounts() {
    responseRef.value ??= await redisCache.get<CartDiscountResponseData>(cacheId, getEnrichedDiscounts);
    discountListRef.value = cartDiscountListModel(responseRef.value);
  }

  async function getEnrichedDiscounts() {
    const params: QueryCartDiscountParams = {
      queryArgs: {
        sort: 'sortOrder desc',
        where: 'isActive = true and requiresDiscountCode = false',
        limit: 100,
      },
    };
    return await nuxtApp.$apiCommercetools.queryDiscounts(params);
  }

  function getValidDiscountForProduct(product: ProductModel) {
    const { customerModelRef } = useMopCustomer();
    const customerGroups: CustomerGroup[] = customerModelRef.value.getCustomerGroups();
    const foundDiscount = discountListRef.value.getDiscountModelList(customerGroups).find((discount) => {
      const predicate = discount.getPredicate();
      if (!predicate || predicate === '1 = 1') {
        // i.e. free shipping
        return true;
      }

      const predicateConditionType = predicate.includes(' or ') ? 'some' : 'every';
      const predicateItems = predicateConditionType === 'some' ? predicate.split(' or ') : predicate.split(' and ');
      const productDiscountId = product.getPrice().productDiscountId;

      // Extend list for more use discount / promotion use cases
      return predicateItems[predicateConditionType]((predicteItem) => {
        // Min quantitiy (Belgium frange)
        if (predicteItem.startsWith('quantity')) {
          return true;
        }
        // No Discounted products
        if (predicteItem.startsWith('price.discount.id is not defined')) {
          return !productDiscountId;
        }
        // Discounted products
        if (predicteItem.startsWith('price.discount.id')) {
          return productDiscountId && predicteItem.includes(productDiscountId);
        }
        // Category id list
        if (predicteItem.startsWith('categories.id contains any')) {
          return product.getCategoryIds()?.some((categoryId) => {
            return predicteItem.includes(categoryId);
          });
        }
        // Attribute promoQualier
        if (predicteItem.startsWith('attributes.promoQualifier')) {
          const match = predicteItem.match(/attributes\.promoQualifier\s*=\s*(true|false)/);
          if (match) {
            return product.isPromoQualified() === (match[1] === 'true');
          }
        }
        const ignoreUnknownPredicatItems = predicateConditionType === 'every';
        return ignoreUnknownPredicatItems;
      });
    });
    return foundDiscount;
  }

  function getActiveCartDiscount(product: ProductModel) {
    if (!isClient) {
      return;
    }

    return getValidDiscountForProduct(product);
  }

  return securedWrap({
    initAvailableCartDiscounts,
    getActiveCartDiscount,
  });
}
