import { isClient, loadScript } from '@mop/shared/utils/util';
import { securedWrap } from '@mop/shared/utils/securedWrap';
import type {
  GtmPageViewEvent,
  GtmProductImpressionEvent,
  GtmProductTrackingParams,
  GtmContentViewEvent,
  GtmContentClickEvent,
  GtmCheckoutEvent,
} from '@/types/gtm';
import type { CartLineItemModel } from '@/types/cart';
const MAX_DATALAYER_BULK_SIZE = 15;

export default defineNuxtPlugin((nuxtApp) => {
  const app = nuxtApp.vueApp.$nuxt;
  if (!app.$mopI18n) {
    return;
  }

  const { $mopI18n, $gtm2 } = app;
  const config = useRuntimeConfig();
  const { isConsentRequired } = useMopUsercentricsConsentClient();
  let isGtmScriptAdded = false;
  let datalayerBulk: any = [];
  let currentPage = '';

  if (isEnabled()) {
    initGtm();
  }

  function isEnabled(): boolean {
    return (
      isClient &&
      config.public.GTM_ENABLE &&
      config.public.GTM_CONTAINER_ID !== '' &&
      !useRoute().query.disablegtm &&
      app.$storyblokLivePreview.isEnabled === false
    );
  }

  function pushData(event: any, bulkOnly = false) {
    if (isGtmScriptAdded) {
      if (bulkOnly) {
        datalayerBulk.push(event);
      } else {
        window.dataLayer.push(event);
      }
    } else {
      datalayerBulk.push(event);
    }
    if (datalayerBulk.length >= MAX_DATALAYER_BULK_SIZE) {
      commitDatalayer();
    }
  }

  function initGtm() {
    window.dataLayer = window.dataLayer || [];
    pushData({
      originalLocation:
        document.location.protocol +
        '//' +
        document.location.hostname +
        document.location.pathname +
        document.location.search,
    });

    window.addEventListener('beforeunload', commitDatalayer);
    window.addEventListener('blur', commitDatalayer);

    initVueRouterGuard();
  }

  function initVueRouterGuard() {
    if (!isEnabled()) {
      return;
    }
    const router = useRouter();
    router.beforeEach((to) => {
      if (!to.fullPath) {
        return;
      }
      commitDatalayer();
    });
  }

  function trackView(path: string) {
    if (!isEnabled()) {
      return;
    }
    pushData({
      event: 'page-view',
      dl_page: path,
    });
  }

  function trackPageView(pageViewEvent: GtmPageViewEvent) {
    // datalayer not present if not enabled
    window.dataLayer?.push(pageViewEvent);
    currentPage = pageViewEvent.PageType;
  }

  function trackEvent(
    {
      event = null,
      category = null,
      action = null,
      label = null,
      value = null,
      noninteraction = false,
      ...rest
    }: any = {},
    ignoreBundling = false,
  ) {
    if (!isEnabled()) {
      return;
    }

    pushData(
      {
        event: event || 'interaction',
        target: category,
        ...action,
        'target-properties': label,
        value,
        'interaction-type': noninteraction,
        ...rest,
      },
      !ignoreBundling,
    );
  }

  function commitDatalayer() {
    if (!isEnabled() || !isGtmScriptAdded || isConsentRequired()) {
      return;
    }
    mergeImpressions();
    // eslint-disable-next-line prefer-spread
    window.dataLayer.push.apply(window.dataLayer, datalayerBulk);
    datalayerBulk = [];
  }

  function mergeImpressions() {
    if (!isEnabled()) {
      return;
    }
    const allEvents: any[] = [];
    let productImpressionToMergeIn: GtmProductImpressionEvent | null = null;
    let promoImpressionToMergeIn: GtmContentViewEvent | null = null;
    let promoClickToMergeIn: GtmContentClickEvent | null = null;

    datalayerBulk.forEach((trackingEvent: any) => {
      switch (trackingEvent.event) {
        case 'productImpression':
          if (!productImpressionToMergeIn) {
            productImpressionToMergeIn = trackingEvent as GtmProductImpressionEvent;
          } else {
            productImpressionToMergeIn.ecommerce.impressions.push(trackingEvent.ecommerce?.impressions?.shift());
          }
          break;

        case 'promoImpression':
          if (!promoImpressionToMergeIn) {
            promoImpressionToMergeIn = trackingEvent as GtmContentViewEvent;
          } else {
            promoImpressionToMergeIn.ecommerce.promoView.promotions.push(
              trackingEvent.ecommerce?.promoView?.promotions?.shift(),
            );
          }
          break;

        case 'promoClick':
          if (!promoClickToMergeIn) {
            promoClickToMergeIn = trackingEvent as GtmContentClickEvent;
          } else {
            promoClickToMergeIn.ecommerce.promoClick.promotions.push(
              trackingEvent.ecommerce?.promoClick?.promotions?.shift(),
            );
          }
          break;

        default:
          allEvents.push(trackingEvent);
          break;
      }
    });

    if (productImpressionToMergeIn) {
      allEvents.push(productImpressionToMergeIn);
    }
    if (promoImpressionToMergeIn) {
      allEvents.push(promoImpressionToMergeIn);
    }
    if (promoClickToMergeIn) {
      allEvents.push(promoClickToMergeIn);
    }

    datalayerBulk = allEvents;
  }

  function clearCartContent() {
    if (!isEnabled()) {
      return;
    }
    pushData(GtmUtils.getCartContentClearEvent());
  }

  function updateCartContent(lineItems: CartLineItemModel[]) {
    if (!isEnabled()) {
      return;
    }
    const trackingEvents: GtmProductTrackingParams[] = [];
    lineItems.forEach((cartLineItem: CartLineItemModel, index: number) => {
      trackingEvents.push({
        product: cartLineItem.getProduct(),
        variant: cartLineItem.getVariant(),
        quantity: cartLineItem.getQuantity(),
        position: index + 1,
      });
    });
    pushData(GtmUtils.getCartContentEvent(trackingEvents, $mopI18n.currency));
  }

  function trackCheckout(payload: GtmCheckoutEvent) {
    if (!isEnabled()) {
      return;
    }
    pushData({ ecommerce: null });
    pushData(payload);
  }

  function getCurrentPage() {
    return currentPage;
  }

  function initGtmScript() {
    if (isGtmScriptAdded || !isEnabled() || isConsentRequired()) {
      return;
    }
    isGtmScriptAdded = true;
    commitDatalayer();
    $gtm2.initTracking();
    loadScript({
      source: `https://www.googletagmanager.com/gtm.js?id=${config.public.GTM_CONTAINER_ID}`,
      callback: () => {
        pushData({
          event: 'gtm.js',
          'gtm.start': new Date().getTime(),
          'gtm.uniqueEventId': 0,
        });
      },
    });
  }

  function getPageCategoryID() {
    if (!isEnabled()) {
      return '';
    }
    const pageInformationData: Array<GtmPageViewEvent> = window.dataLayer.filter((item: any) => item.PageCategoryID);
    return pageInformationData.length > 0 ? pageInformationData?.pop()?.PageCategoryID : '';
  }

  nuxtApp.provide(
    'gtm',
    securedWrap({
      isEnabled,
      trackEvent,
      trackView,
      trackCheckout,
      trackPageView,
      clearCartContent,
      updateCartContent,
      getCurrentPage,
      initGtmScript,
      getPageCategoryID,
      isGtmScriptAdded,
    }),
  );
});
