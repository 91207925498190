import { isClient, parseAxiosErrorResponseUrl } from '@mop/shared/utils/util';
import StoryblokClient from 'storyblok-js-client';
import type { AxiosError, AxiosInstance } from 'axios';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import { logError, logInfo } from '@mop/shared/utils/logger';
import type { ByProjectKeyRequestBuilder } from '@commercetools/platform-sdk';
import type { Next } from '@commercetools/sdk-client-v2';
import cookie from '@mop/shared/utils/cookie';
import type { NuxtApp } from '#app';
import type {
  APIAwsSetupParams,
  APICmsSetupParams,
  APICommercetoolsSetupParams,
  APICrmMiddlewareSetupParams,
  APIEpoqSetupParams,
  APIWalletSetupParams,
  APIBffSetupParams,
} from '@/types/apiInit';
import type { ApiCmsInstance } from '@/types/cms';
import { initApiCommercetools } from '@/api/providers/commercetools';
import { initApiEpoq } from '@/api/providers/epoq';
import { initApiWallet } from '@/api/providers/wallet';
import apiCms from '@/api/providers/cms';
import { initApiBff, type ApiBff } from '@/api/providers/bff';
import apiCrmMiddleware from '@/api/providers/crm';
import apiLocal from '@/api/providers/local';
import { commercetoolsTokenModel } from '@/models';

const numberOfRetries: number = isClient ? 1 : 0;

// Max 1 connection
let apiLocalCache: AxiosInstance;
// Max 2 connections
const apiCmsCache: { [key: string]: StoryblokClient } = {};
// Max 1 connection
let apiCrmCache: AxiosInstance;
// Max 1 connection
let apiAwsCache: AxiosInstance;

const tokenExpiration = {
  accessToken: '',
  expires: 0,
};

const timeFullDay = 1000 * 60 * 60 * 24;

async function getAccessToken(middlewareApiClient: ApiBff) {
  // expire server side faster to give client side at min 24h of expiration
  const expirationTime = isClient ? tokenExpiration.expires : tokenExpiration.expires - timeFullDay;
  if (expirationTime <= Date.now() || !tokenExpiration.accessToken) {
    const tokenModel = commercetoolsTokenModel(await middlewareApiClient.getToken());
    if (!tokenModel.getAccessToken()) {
      try {
        logError(new Error('Token fetch failed'));
      } catch (e) {
        // do nothing
      }
      return;
    }
    tokenExpiration.expires = tokenModel.getExpiration();
    tokenExpiration.accessToken = tokenModel.getAccessToken();
  }

  // copy
  return JSON.parse(JSON.stringify(tokenExpiration)) as typeof tokenExpiration;
}

function retryCondition(error: AxiosError) {
  logInfo(`Retrying: ${parseAxiosErrorResponseUrl(error)}`);
  return true;
}

function getLocalClient() {
  if (apiLocalCache) {
    return apiLocalCache;
  }
  apiLocalCache = axios.create({
    timeout: 10000,
    responseType: 'json',
  });

  axiosRetry(apiLocalCache, {
    retries: numberOfRetries,
    shouldResetTimeout: true,
    retryCondition,
  });

  return apiLocalCache;
}

function createApiLocal() {
  return apiLocal(getLocalClient());
}

function getCmsClient(setupParams: APICmsSetupParams) {
  if (!apiCmsCache[setupParams.cmsApiKey]) {
    const client: StoryblokClient = (apiCmsCache[setupParams.cmsApiKey] = new StoryblokClient({
      accessToken: setupParams.cmsApiKey,
      rateLimit: 1000,
      timeout: isClient ? 10000 : 5000,
      fetch: isClient ? window.fetch.bind(window) : fetch,
    }));
    apiCmsCache[setupParams.cmsApiKey] = client;
  }
  return apiCmsCache[setupParams.cmsApiKey];
}

function createApiCms(setupParams: APICmsSetupParams) {
  const cmsClient: ApiCmsInstance = {
    client: getCmsClient(setupParams),
    config: {
      cmsVersion: setupParams.cmsVersion,
      cmsLanguage: setupParams.cmsLanguage,
      cmsFallbackLanguage: setupParams.cmsFallbackLanguage,
      cmsRelease: setupParams.cmsRelease,
    },
  };

  return apiCms(cmsClient);
}

function getCrmClient(setupParams: APICrmMiddlewareSetupParams) {
  if (!apiCrmCache) {
    const client = axios.create({
      baseURL: setupParams.url,
      timeout: 60000,
      responseType: 'json',
    });
    axiosRetry(client, {
      retries: 0,
      shouldResetTimeout: true,
      retryCondition,
    });
    apiCrmCache = client;
  }
  return apiCrmCache;
}

function createApiCrmMiddleware(setupParams: APICrmMiddlewareSetupParams) {
  return apiCrmMiddleware(getCrmClient(setupParams));
}

async function createApiCommercetools(setupParams: APICommercetoolsSetupParams, nuxtApp: NuxtApp) {
  const createApiBuilderFromCtpClient = nuxtApp.vueApp.$nuxt.$commercetools.createApiBuilderFromCtpClient;
  const ClientBuilder = nuxtApp.vueApp.$nuxt.$commercetools.ClientBuilder;

  const { host, projectKey, middlewareApiClient } = setupParams;
  if (isClient) {
    const token = nuxtApp.payload.data.token;
    // in case server failed to return token, refetch for client
    if (!token?.accessToken) {
      await getAccessToken(middlewareApiClient);
    } else {
      tokenExpiration.expires = token.expires;
      tokenExpiration.accessToken = token.accessToken;
      // check if not expired
      await getAccessToken(middlewareApiClient);
    }
  } else {
    nuxtApp.payload.data.token = await getAccessToken(middlewareApiClient);
  }

  const client = new ClientBuilder()
    .withHttpMiddleware({
      host,
      fetch,
    })
    .withBeforeExecutionMiddleware({
      name: 'pre-process-token',
      middleware() {
        return (next: Next): Next => {
          return async (req, res) => {
            const token = await getAccessToken(middlewareApiClient);
            req.headers ??= {};
            req.headers.Authorization = `Bearer ${token?.accessToken}`;
            next(req, res);
          };
        };
      },
    })
    .build();

  const apiClient: ByProjectKeyRequestBuilder = createApiBuilderFromCtpClient(client).withProjectKey({
    projectKey,
  });

  return initApiCommercetools({
    apiClient,
    lang: setupParams.lang,
    country: setupParams.country,
    commercetoolsCountry: setupParams.commercetoolsCountry,
    currency: setupParams.currency,
  });
}

function getApiBffClient(setupParams: APIAwsSetupParams) {
  if (!apiAwsCache) {
    const client: AxiosInstance = axios.create({
      baseURL: setupParams.url,
      timeout: isClient ? 15000 : 5000,
      responseType: 'json',
    });
    if (isClient) {
      client.interceptors.request.use((config) => {
        const token = cookie().get(constants.COOKIE.SESSION_HEADER);
        config.headers.Authorization = token;
        return config;
      });
    }
    axiosRetry(client, {
      retries: numberOfRetries,
      shouldResetTimeout: true,
      retryCondition,
    });
    apiAwsCache = client;
  }
  return apiAwsCache;
}

function createApiBff(setupParams: APIBffSetupParams) {
  return initApiBff({
    apiClient: getApiBffClient(setupParams),
    lang: setupParams.lang,
    country: setupParams.country,
    commercetoolsCountry: setupParams.commercetoolsCountry,
    currency: setupParams.currency,
  });
}

function getEpoqClient(setupParams: APIEpoqSetupParams) {
  const client = axios.create({
    baseURL: setupParams.url,
    timeout: 10000,
    responseType: 'json',
  });
  axiosRetry(client, {
    retries: numberOfRetries,
    shouldResetTimeout: true,
    retryCondition,
  });
  return client;
}

function createApiEpoq(setupParams: APIEpoqSetupParams) {
  return initApiEpoq(getEpoqClient(setupParams));
}

function getWalletClient(setupParams: APIWalletSetupParams) {
  const client: AxiosInstance = axios.create({
    baseURL: setupParams.url,
    responseType: 'json',
  });
  return client;
}

function createApiWallet(setupParams: APIWalletSetupParams) {
  return initApiWallet(getWalletClient(setupParams));
}

export {
  createApiLocal,
  createApiCms,
  createApiCrmMiddleware,
  createApiBff,
  createApiEpoq,
  createApiCommercetools,
  createApiWallet,
};
