import type { StoreModel, Service } from '@/types/store';
import type { BffApiStoresGetResponse, BffStore, BffApiOpticalStoresGetResponse } from '@/types/bff';
import type { ApiResponse } from '@/types/apiInit';

export function storeModel(responseData: BffStore) {
  return {
    getId(): string {
      return responseData.id;
    },

    getGln(): string {
      return responseData.gln;
    },

    getName(): string {
      return responseData.name;
    },

    getCountry(): string {
      return responseData.country;
    },

    getMail(): string {
      return responseData.mail;
    },

    getAddress() {
      return responseData.address;
    },

    getOpeningTimes() {
      return responseData.openingTimes;
    },

    getStoreCoordinates() {
      return responseData.storeGeolocation;
    },

    getPhoneNumber(): string {
      return responseData.phoneNumber;
    },

    getCollection() {
      return responseData.collection;
    },

    isClickAndCollectEnabled() {
      return this.getServices().includes('click_and_collect');
    },

    isReserveAndCollectEnabled() {
      return this.getServices().includes('reserve_and_collect');
    },

    getServices() {
      const services = responseData?.services;

      if (!services) {
        return [];
      }

      const sortOrder: string[] = [
        'bonus_points',
        'reserve_and_collect',
        'click_and_collect',
        'shopping_appointment',
        'voucher_redemption',
      ] satisfies Service[];

      return services.sort((a, b) => sortOrder.indexOf(a) - sortOrder.indexOf(b));
    },

    getStockQuantity(): number | null {
      return responseData?.stockQuantity ?? null;
    },

    getDebugData() {
      return responseData;
    },
  };
}

export function storeListModel(
  responseData: ApiResponse<BffApiStoresGetResponse | BffApiOpticalStoresGetResponse> | null,
) {
  const data = responseData?.data;

  return {
    hasError(): boolean {
      return data?.code !== 'OK';
    },

    getStoreModelList(params?: { services: string[] }): StoreModel[] {
      if (!data?.stores || data?.stores.length === 0) {
        return [];
      }

      // Remove stores without GeoCoordinates and not including specified service
      const filteredStores = (data as BffApiStoresGetResponse)?.stores.filter(
        (storeData) =>
          storeData.storeGeolocation &&
          (!params?.services.length || storeData.services.some((service) => params?.services.includes(service))),
      );
      return filteredStores.map((storeData) => storeModel(storeData));
    },

    isInitialized(): boolean {
      return responseData !== null;
    },
  };
}
