import type { AxiosError } from 'axios';
import { handleAxiosError, bffErrorHandler, getBffUrlWithParams } from '@/api/utils';
import type { ApiResponse, ApiBffConfig } from '@/types/apiInit';
import type { StoreSearchParams } from '@/types/store';
import type {
  BffApiOpticalStoresGetRequest,
  BffApiOpticalStoresGetResponse,
  BffApiStoresGetResponse,
  BffApiStoresIdGetResponse,
} from '@/types/bff';
const API_NAME = 'AWS - BFF STORES';

export function getStores(
  apiConfig: ApiBffConfig,
  params: StoreSearchParams,
): Promise<ApiResponse<BffApiStoresGetResponse>> {
  const url = getBffUrlWithParams('/store', apiConfig);

  return bffErrorHandler<ApiResponse<BffApiStoresGetResponse>>(async () => ({
    data: (
      await apiConfig.apiClient.get(url, {
        params,
        withCredentials: true,
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'getStores',
      url,
    }),
  }));
}

export function getStore(
  apiConfig: ApiBffConfig,
  id: string,
  params?: { ean?: string },
): Promise<ApiResponse<BffApiStoresIdGetResponse>> {
  const url = getBffUrlWithParams(`/store/${id}`, apiConfig);

  return bffErrorHandler<ApiResponse<BffApiStoresIdGetResponse>>(async () => ({
    data: (
      await apiConfig.apiClient.get(url, {
        params,
        withCredentials: true,
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'getStore',
      url,
    }),
  }));
}

export function getOpticalStores(
  apiConfig: ApiBffConfig,
  params: BffApiOpticalStoresGetRequest,
): Promise<ApiResponse<BffApiOpticalStoresGetResponse>> {
  const url = getBffUrlWithParams('/optical-store', apiConfig);

  return bffErrorHandler<ApiResponse<BffApiOpticalStoresGetResponse>>(async () => ({
    data: (
      await apiConfig.apiClient.get(url, {
        params,
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'getOpticalStores',
      url,
    }),
  }));
}
