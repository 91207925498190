export const appBuildAssetsDir = "/_nuxt2/"

export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"description","content":"Marc O’Polo online shop"},{"name":"apple-mobile-web-app-title","content":"Marc O’Polo"},{"name":"application-name","content":"Marc O’Polo"},{"name":"msapplication-TileColor","content":"#ffffff"},{"name":"theme-color","content":"#ffffff"},{"name":"google-site-verification","content":"xDAr8LaIqv_jExlYFNCRD2suyk-97lrFafNz45PVKgw"}],"link":[{"rel":"apple-touch-icon","sizes":"180x180","href":"/apple-touch-icon.png"},{"rel":"shortcut icon","href":"/favicon.ico"},{"rel":"icon","type":"image/png","sizes":"32x32","href":"/favicon-32x32.png"},{"rel":"manifest","href":"/manifest.json"},{"rel":"mask-icon","href":"/safari-pinned-tab.svg"}],"style":[],"script":[{"rel":"preload","src":"https://js.monitor.azure.com/scripts/b/ai.2.min.js","crossorigin":"anonymous","async":true}],"noscript":[]}

export const appBaseURL = "/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'