import { default as indexDRUA3wU2iBMeta } from "/home/vsts/work/1/s/apps/b2c/pages/index.vue?macro=true";
import { default as AccountOverviewJHeZ7LyQ08Meta } from "/views/account/AccountOverview.vue?macro=true";
import { default as AccountProfileb9f8MHfUm1Meta } from "/views/account/AccountProfile.vue?macro=true";
import { default as AccountOrdersUUr6VMgXz1Meta } from "/views/account/AccountOrders.vue?macro=true";
import { default as AccountOrderKonjcLHY1hMeta } from "/views/account/AccountOrder.vue?macro=true";
import { default as AccountMembersXJTdEOIutZMeta } from "/views/account/AccountMembers.vue?macro=true";
import { default as AccountWrapperzh7ol9fcByMeta } from "/views/account/components/AccountWrapper.vue?macro=true";
import { default as AuthLoginVFgUkBFtB7Meta } from "/views/auth/AuthLogin.vue?macro=true";
import { default as AuthRegisterxqE7GhMZXMMeta } from "/views/auth/AuthRegister.vue?macro=true";
import { default as AuthPasswordReset5zH30B05OYMeta } from "/views/auth/AuthPasswordReset.vue?macro=true";
import { default as AuthPasswordResetSubmitVwwededBQ5Meta } from "/views/auth/AuthPasswordResetSubmit.vue?macro=true";
import { default as AuthWrapperrTbJb1lEBKMeta } from "/views/auth/components/AuthWrapper.vue?macro=true";
import { default as CheckoutStepAddressesrOUl035C3DMeta } from "/views/checkout/CheckoutStepAddresses.vue?macro=true";
import { default as CheckoutStepBillingLzrlFcLqIEMeta } from "/views/checkout/CheckoutStepBilling.vue?macro=true";
import { default as CheckoutStepShippingVnd9Dx6FEdMeta } from "/views/checkout/CheckoutStepShipping.vue?macro=true";
import { default as CheckoutStepPaymentxYIdmFsp0TMeta } from "/views/checkout/CheckoutStepPayment.vue?macro=true";
import { default as CheckoutWrapperK6HCigTgOPMeta } from "/views/checkout/CheckoutWrapper.vue?macro=true";
import { default as ProductPageQjLHbb2o63Meta } from "/views/product/ProductPage.vue?macro=true";
import { default as HomeU1vJlFwouUMeta } from "/views/index/Home.vue?macro=true";
import { default as GiftCardPageYZSruGijFoMeta } from "/views/product/GiftCardPage.vue?macro=true";
import { default as ThankYou3JNUtaC3L2Meta } from "/views/thankYou/ThankYou.vue?macro=true";
import { default as ContactRO9cQ0e41yMeta } from "/views/contact/Contact.vue?macro=true";
import { default as ShipmentTrackingLFLMUeS4MlMeta } from "/views/shipmentTracking/ShipmentTracking.vue?macro=true";
import { default as ReturnPortalkwFmYJNG6mMeta } from "/views/returnPortal/ReturnPortal.vue?macro=true";
import { default as TestYNc71IId3XMeta } from "/views/test/Test.vue?macro=true";
import { default as TestStoryblokn6diARh4CFMeta } from "/views/test/TestStoryblok.vue?macro=true";
import { default as StoreFinderrW0MMkGJqlMeta } from "/views/storeFinder/StoreFinder.vue?macro=true";
import { default as ShoppingAppointmentLEie1yE7ZXMeta } from "/views/shoppingAppointment/ShoppingAppointment.vue?macro=true";
import { default as Memberseiv0Lyyye4Meta } from "/views/members/Members.vue?macro=true";
import { default as GlobalE3qNHnQqBlwMeta } from "/views/checkout/GlobalE.vue?macro=true";
import { default as StoreAppPageuymq5iByMfMeta } from "/views/storeApp/StoreAppPage.vue?macro=true";
import { default as NewsletterRegistrationV2gxCb12rGYAMeta } from "/views/newsletter/NewsletterRegistrationV2.vue?macro=true";
import { default as NewsletterRegistrationV1ySJBmDgAGaMeta } from "/views/newsletter/NewsletterRegistrationV1.vue?macro=true";
import { default as NewsletterConfirmationSFXSjVeGw5Meta } from "/views/newsletter/NewsletterConfirmation.vue?macro=true";
import { default as PreferenceCenterSPSm87R59aMeta } from "/views/newsletter/PreferenceCenter.vue?macro=true";
import { default as StaticPage9cOFfHk3LgMeta } from "/views/content/StaticPage.vue?macro=true";
import { default as MarketingPagerTWWkW1FamMeta } from "/views/content/MarketingPage.vue?macro=true";
import { default as Category5DQDcCpHAcMeta } from "/views/category/Category.vue?macro=true";
import { default as WishlistxS6mD6PsuMMeta } from "/views/wishlist/Wishlist.vue?macro=true";
import { default as CartAaB4tRYXFgMeta } from "/views/cart/Cart.vue?macro=true";
import { default as StoreReservationjOufpsF1FBMeta } from "/views/storeReservation/StoreReservation.vue?macro=true";
import { default as EmptyLKulokmmacMeta } from "/views/index/Empty.vue?macro=true";
import { default as WalletLandingb4uBgQbLsWMeta } from "/views/wallet/WalletLanding.vue?macro=true";
export default [
  {
    name: indexDRUA3wU2iBMeta?.name ?? "index",
    path: indexDRUA3wU2iBMeta?.path ?? "/",
    meta: indexDRUA3wU2iBMeta || {},
    alias: indexDRUA3wU2iBMeta?.alias || [],
    redirect: indexDRUA3wU2iBMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/apps/b2c/pages/index.vue").then(m => m.default || m)
  },
  {
    name: AccountWrapperzh7ol9fcByMeta?.name ?? "account-overview",
    path: AccountWrapperzh7ol9fcByMeta?.path ?? "/:lang([a-z]{2}-[a-z]{2})/account",
    meta: {...(AccountWrapperzh7ol9fcByMeta || {}), ...{"cacheMaxAgeInSeconds":86400,"requiresAuth":true,"requiresRegistration":true,"isNotIndexable":true,"linkPath":"/account"}},
    children: [
  {
    name: AccountOverviewJHeZ7LyQ08Meta?.name ?? "account-overview",
    path: AccountOverviewJHeZ7LyQ08Meta?.path ?? "/:lang([a-z]{2}-[a-z]{2})/account",
    meta: {...(AccountOverviewJHeZ7LyQ08Meta || {}), ...{"cacheMaxAgeInSeconds":86400,"requiresAuth":true,"requiresRegistration":true,"isNotIndexable":true,"linkPath":"/account"}},
    alias: AccountOverviewJHeZ7LyQ08Meta?.alias || [],
    redirect: AccountOverviewJHeZ7LyQ08Meta?.redirect || undefined,
    component: () => import("/views/account/AccountOverview.vue").then(m => m.default || m)
  },
  {
    name: AccountProfileb9f8MHfUm1Meta?.name ?? "account-profile",
    path: AccountProfileb9f8MHfUm1Meta?.path ?? "/:lang([a-z]{2}-[a-z]{2})/account/profile",
    meta: {...(AccountProfileb9f8MHfUm1Meta || {}), ...{"cacheMaxAgeInSeconds":86400,"requiresAuth":true,"requiresRegistration":true,"isNotIndexable":true,"linkPath":"/account/profile"}},
    alias: AccountProfileb9f8MHfUm1Meta?.alias || [],
    redirect: AccountProfileb9f8MHfUm1Meta?.redirect || undefined,
    component: () => import("/views/account/AccountProfile.vue").then(m => m.default || m)
  },
  {
    name: AccountOrdersUUr6VMgXz1Meta?.name ?? "account-orders",
    path: AccountOrdersUUr6VMgXz1Meta?.path ?? "/:lang([a-z]{2}-[a-z]{2})/account/orders",
    meta: {...(AccountOrdersUUr6VMgXz1Meta || {}), ...{"cacheMaxAgeInSeconds":86400,"requiresAuth":true,"requiresRegistration":true,"isNotIndexable":true,"linkPath":"/account/orders"}},
    alias: AccountOrdersUUr6VMgXz1Meta?.alias || [],
    redirect: AccountOrdersUUr6VMgXz1Meta?.redirect || undefined,
    component: () => import("/views/account/AccountOrders.vue").then(m => m.default || m)
  },
  {
    name: AccountOrderKonjcLHY1hMeta?.name ?? "account-order",
    path: AccountOrderKonjcLHY1hMeta?.path ?? "/:lang([a-z]{2}-[a-z]{2})/account/order/:id",
    meta: {...(AccountOrderKonjcLHY1hMeta || {}), ...{"cacheMaxAgeInSeconds":86400,"requiresAuth":true,"requiresRegistration":true,"isNotIndexable":true,"hideInAccountNavigation":true}},
    alias: AccountOrderKonjcLHY1hMeta?.alias || [],
    redirect: AccountOrderKonjcLHY1hMeta?.redirect || undefined,
    component: () => import("/views/account/AccountOrder.vue").then(m => m.default || m)
  },
  {
    name: AccountMembersXJTdEOIutZMeta?.name ?? "account-members",
    path: AccountMembersXJTdEOIutZMeta?.path ?? "/:lang([a-z]{2}-[a-z]{2})/account/members",
    meta: {...(AccountMembersXJTdEOIutZMeta || {}), ...{"cacheMaxAgeInSeconds":86400,"requiresAuth":true,"requiresRegistration":true,"isNotIndexable":true,"loyaltyCountryOnly":true,"linkPath":"/account/members"}},
    alias: AccountMembersXJTdEOIutZMeta?.alias || [],
    redirect: AccountMembersXJTdEOIutZMeta?.redirect || undefined,
    component: () => import("/views/account/AccountMembers.vue").then(m => m.default || m)
  }
],
    alias: AccountWrapperzh7ol9fcByMeta?.alias || [],
    redirect: AccountWrapperzh7ol9fcByMeta?.redirect || undefined,
    component: () => import("/views/account/components/AccountWrapper.vue").then(m => m.default || m)
  },
  {
    name: AuthWrapperrTbJb1lEBKMeta?.name ?? "auth",
    path: AuthWrapperrTbJb1lEBKMeta?.path ?? "/:lang([a-z]{2}-[a-z]{2})/auth",
    meta: {...(AuthWrapperrTbJb1lEBKMeta || {}), ...{"cacheMaxAgeInSeconds":86400,"hideSuperbanner":true,"hideHeader":true,"hideFooter":true,"linkPath":"/auth"}},
    children: [
  {
    name: AuthLoginVFgUkBFtB7Meta?.name ?? "auth-login",
    path: AuthLoginVFgUkBFtB7Meta?.path ?? "/:lang([a-z]{2}-[a-z]{2})/auth/login",
    meta: {...(AuthLoginVFgUkBFtB7Meta || {}), ...{"cacheMaxAgeInSeconds":86400,"hideSuperbanner":true,"hideHeader":true,"hideFooter":true,"linkPath":"/auth/login"}},
    alias: AuthLoginVFgUkBFtB7Meta?.alias || [],
    redirect: AuthLoginVFgUkBFtB7Meta?.redirect || undefined,
    component: () => import("/views/auth/AuthLogin.vue").then(m => m.default || m)
  },
  {
    name: AuthRegisterxqE7GhMZXMMeta?.name ?? "auth-register",
    path: AuthRegisterxqE7GhMZXMMeta?.path ?? "/:lang([a-z]{2}-[a-z]{2})/auth/register",
    meta: {...(AuthRegisterxqE7GhMZXMMeta || {}), ...{"cacheMaxAgeInSeconds":86400,"hideSuperbanner":true,"hideHeader":true,"hideFooter":true,"linkPath":"/auth/register"}},
    alias: AuthRegisterxqE7GhMZXMMeta?.alias || [],
    redirect: AuthRegisterxqE7GhMZXMMeta?.redirect || undefined,
    component: () => import("/views/auth/AuthRegister.vue").then(m => m.default || m)
  },
  {
    name: AuthPasswordReset5zH30B05OYMeta?.name ?? "auth-password-reset",
    path: AuthPasswordReset5zH30B05OYMeta?.path ?? "/:lang([a-z]{2}-[a-z]{2})/auth/password-reset",
    meta: {...(AuthPasswordReset5zH30B05OYMeta || {}), ...{"cacheMaxAgeInSeconds":86400,"hideSuperbanner":true,"hideHeader":true,"hideFooter":true,"linkPath":"/auth/password-reset"}},
    alias: AuthPasswordReset5zH30B05OYMeta?.alias || [],
    redirect: AuthPasswordReset5zH30B05OYMeta?.redirect || undefined,
    component: () => import("/views/auth/AuthPasswordReset.vue").then(m => m.default || m)
  },
  {
    name: AuthPasswordResetSubmitVwwededBQ5Meta?.name ?? "auth-password-reset-submit",
    path: AuthPasswordResetSubmitVwwededBQ5Meta?.path ?? "/:lang([a-z]{2}-[a-z]{2})/auth/password-reset-submit",
    meta: {...(AuthPasswordResetSubmitVwwededBQ5Meta || {}), ...{"cacheMaxAgeInSeconds":86400,"hideSuperbanner":true,"hideHeader":true,"hideFooter":true,"linkPath":"/auth/password-reset-submit"}},
    alias: AuthPasswordResetSubmitVwwededBQ5Meta?.alias || [],
    redirect: AuthPasswordResetSubmitVwwededBQ5Meta?.redirect || undefined,
    component: () => import("/views/auth/AuthPasswordResetSubmit.vue").then(m => m.default || m)
  }
],
    alias: AuthWrapperrTbJb1lEBKMeta?.alias || [],
    redirect: AuthWrapperrTbJb1lEBKMeta?.redirect || undefined,
    component: () => import("/views/auth/components/AuthWrapper.vue").then(m => m.default || m)
  },
  {
    name: CheckoutWrapperK6HCigTgOPMeta?.name ?? "checkout",
    path: CheckoutWrapperK6HCigTgOPMeta?.path ?? "/:lang([a-z]{2}-[a-z]{2})/checkout",
    meta: {...(CheckoutWrapperK6HCigTgOPMeta || {}), ...{"isNotIndexable":true,"cacheMaxAgeInSeconds":86400,"hideSuperbanner":true,"hideHeader":true,"hideFooter":true,"disableChat":true,"enableRum":true,"datadogGroup":"checkout"}},
    children: [
  {
    name: CheckoutStepAddressesrOUl035C3DMeta?.name ?? "checkout-addresses",
    path: CheckoutStepAddressesrOUl035C3DMeta?.path ?? "/:lang([a-z]{2}-[a-z]{2})/checkout/addresses",
    meta: {...(CheckoutStepAddressesrOUl035C3DMeta || {}), ...{"isNotIndexable":true,"cacheMaxAgeInSeconds":86400,"hideSuperbanner":true,"hideHeader":true,"hideFooter":true,"disableChat":true,"enableRum":true,"datadogGroup":"checkout"}},
    alias: CheckoutStepAddressesrOUl035C3DMeta?.alias || [],
    redirect: CheckoutStepAddressesrOUl035C3DMeta?.redirect || undefined,
    component: () => import("/views/checkout/CheckoutStepAddresses.vue").then(m => m.default || m)
  },
  {
    name: CheckoutStepBillingLzrlFcLqIEMeta?.name ?? "checkout-billing",
    path: CheckoutStepBillingLzrlFcLqIEMeta?.path ?? "/:lang([a-z]{2}-[a-z]{2})/checkout/billing",
    meta: {...(CheckoutStepBillingLzrlFcLqIEMeta || {}), ...{"isNotIndexable":true,"cacheMaxAgeInSeconds":86400,"hideSuperbanner":true,"hideHeader":true,"hideFooter":true,"disableChat":true,"enableRum":true,"datadogGroup":"checkout"}},
    alias: CheckoutStepBillingLzrlFcLqIEMeta?.alias || [],
    redirect: CheckoutStepBillingLzrlFcLqIEMeta?.redirect || undefined,
    component: () => import("/views/checkout/CheckoutStepBilling.vue").then(m => m.default || m)
  },
  {
    name: CheckoutStepShippingVnd9Dx6FEdMeta?.name ?? "checkout-shipping",
    path: CheckoutStepShippingVnd9Dx6FEdMeta?.path ?? "/:lang([a-z]{2}-[a-z]{2})/checkout/shipping",
    meta: {...(CheckoutStepShippingVnd9Dx6FEdMeta || {}), ...{"isNotIndexable":true,"cacheMaxAgeInSeconds":86400,"hideSuperbanner":true,"hideHeader":true,"hideFooter":true,"disableChat":true,"enableRum":true,"datadogGroup":"checkout"}},
    alias: CheckoutStepShippingVnd9Dx6FEdMeta?.alias || [],
    redirect: CheckoutStepShippingVnd9Dx6FEdMeta?.redirect || undefined,
    component: () => import("/views/checkout/CheckoutStepShipping.vue").then(m => m.default || m)
  },
  {
    name: CheckoutStepPaymentxYIdmFsp0TMeta?.name ?? "checkout-payment",
    path: CheckoutStepPaymentxYIdmFsp0TMeta?.path ?? "/:lang([a-z]{2}-[a-z]{2})/checkout/payment",
    meta: {...(CheckoutStepPaymentxYIdmFsp0TMeta || {}), ...{"isNotIndexable":true,"cacheMaxAgeInSeconds":86400,"hideSuperbanner":true,"hideHeader":true,"hideFooter":true,"disableChat":true,"enableRum":true,"datadogGroup":"checkout","hideInAccountNavigation":true}},
    alias: CheckoutStepPaymentxYIdmFsp0TMeta?.alias || [],
    redirect: CheckoutStepPaymentxYIdmFsp0TMeta?.redirect || undefined,
    component: () => import("/views/checkout/CheckoutStepPayment.vue").then(m => m.default || m)
  }
],
    alias: CheckoutWrapperK6HCigTgOPMeta?.alias || [],
    redirect: CheckoutWrapperK6HCigTgOPMeta?.redirect || undefined,
    component: () => import("/views/checkout/CheckoutWrapper.vue").then(m => m.default || m)
  },
  {
    name: ProductPageQjLHbb2o63Meta?.name ?? "product-legacy-page",
    path: ProductPageQjLHbb2o63Meta?.path ?? "/:lang([a-z]{2}-[a-z]{2})/:id(.*).html",
    meta: {...(ProductPageQjLHbb2o63Meta || {}), ...{"disableActiveCategoryReset":true,"cacheMaxAgeInSeconds":86400}},
    alias: ProductPageQjLHbb2o63Meta?.alias || [],
    redirect: ProductPageQjLHbb2o63Meta?.redirect || undefined,
    component: () => import("/views/product/ProductPage.vue").then(m => m.default || m)
  },
  {
    name: ProductPageQjLHbb2o63Meta?.name ?? "variant-legacy-page",
    path: ProductPageQjLHbb2o63Meta?.path ?? "/:lang([a-z]{2}-[a-z]{2})/(.*)Product-Variation(.*)",
    meta: {...(ProductPageQjLHbb2o63Meta || {}), ...{"cacheMaxAgeInSeconds":86400}},
    alias: ProductPageQjLHbb2o63Meta?.alias || [],
    redirect: ProductPageQjLHbb2o63Meta?.redirect || undefined,
    component: () => import("/views/product/ProductPage.vue").then(m => m.default || m)
  },
  {
    name: HomeU1vJlFwouUMeta?.name ?? "home",
    path: HomeU1vJlFwouUMeta?.path ?? "/:lang([a-z]{2}-[a-z]{2})",
    meta: {...(HomeU1vJlFwouUMeta || {}), ...{"cacheMaxAgeInSeconds":86400}},
    alias: HomeU1vJlFwouUMeta?.alias || [],
    redirect: HomeU1vJlFwouUMeta?.redirect || undefined,
    component: () => import("/views/index/Home.vue").then(m => m.default || m)
  },
  {
    name: ProductPageQjLHbb2o63Meta?.name ?? "product-page",
    path: ProductPageQjLHbb2o63Meta?.path ?? "/:lang([a-z]{2}-[a-z]{2})/p/:slug(.*)",
    meta: {...(ProductPageQjLHbb2o63Meta || {}), ...{"cacheMaxAgeInSeconds":86400,"disableActiveCategoryReset":true}},
    alias: ProductPageQjLHbb2o63Meta?.alias || [],
    redirect: ProductPageQjLHbb2o63Meta?.redirect || undefined,
    component: () => import("/views/product/ProductPage.vue").then(m => m.default || m)
  },
  {
    name: GiftCardPageYZSruGijFoMeta?.name ?? "gift-card-page",
    path: GiftCardPageYZSruGijFoMeta?.path ?? "/:lang([a-z]{2}-[a-z]{2})/gift-card/:slug(.*)",
    meta: {...(GiftCardPageYZSruGijFoMeta || {}), ...{"cacheMaxAgeInSeconds":86400}},
    alias: GiftCardPageYZSruGijFoMeta?.alias || [],
    redirect: GiftCardPageYZSruGijFoMeta?.redirect || undefined,
    component: () => import("/views/product/GiftCardPage.vue").then(m => m.default || m)
  },
  {
    name: ThankYou3JNUtaC3L2Meta?.name ?? "thank-you",
    path: ThankYou3JNUtaC3L2Meta?.path ?? "/:lang([a-z]{2}-[a-z]{2})/checkout/success",
    meta: {...(ThankYou3JNUtaC3L2Meta || {}), ...{"cacheMaxAgeInSeconds":86400,"isNotIndexable":true,"enableRum":true}},
    alias: ThankYou3JNUtaC3L2Meta?.alias || [],
    redirect: ThankYou3JNUtaC3L2Meta?.redirect || undefined,
    component: () => import("/views/thankYou/ThankYou.vue").then(m => m.default || m)
  },
  {
    name: ContactRO9cQ0e41yMeta?.name ?? "contact",
    path: ContactRO9cQ0e41yMeta?.path ?? "/:lang([a-z]{2}-[a-z]{2})/contact",
    meta: {...(ContactRO9cQ0e41yMeta || {}), ...{"cacheMaxAgeInSeconds":86400,"isNotIndexable":true}},
    alias: ContactRO9cQ0e41yMeta?.alias || [],
    redirect: ContactRO9cQ0e41yMeta?.redirect || undefined,
    component: () => import("/views/contact/Contact.vue").then(m => m.default || m)
  },
  {
    name: ShipmentTrackingLFLMUeS4MlMeta?.name ?? "shipment-tracking",
    path: ShipmentTrackingLFLMUeS4MlMeta?.path ?? "/:lang([a-z]{2}-[a-z]{2})/shipment-tracking",
    meta: {...(ShipmentTrackingLFLMUeS4MlMeta || {}), ...{"cacheMaxAgeInSeconds":86400,"isNotIndexable":true}},
    alias: ShipmentTrackingLFLMUeS4MlMeta?.alias || [],
    redirect: ShipmentTrackingLFLMUeS4MlMeta?.redirect || undefined,
    component: () => import("/views/shipmentTracking/ShipmentTracking.vue").then(m => m.default || m)
  },
  {
    name: ReturnPortalkwFmYJNG6mMeta?.name ?? "return-portal",
    path: ReturnPortalkwFmYJNG6mMeta?.path ?? "/:lang([a-z]{2}-[a-z]{2})/return-portal",
    meta: {...(ReturnPortalkwFmYJNG6mMeta || {}), ...{"cacheMaxAgeInSeconds":86400,"isNotIndexable":true}},
    alias: ReturnPortalkwFmYJNG6mMeta?.alias || [],
    redirect: ReturnPortalkwFmYJNG6mMeta?.redirect || undefined,
    component: () => import("/views/returnPortal/ReturnPortal.vue").then(m => m.default || m)
  },
  {
    name: TestYNc71IId3XMeta?.name ?? "test",
    path: TestYNc71IId3XMeta?.path ?? "/:lang([a-z]{2}-[a-z]{2})/test/:component?",
    meta: {...(TestYNc71IId3XMeta || {}), ...{"isNotIndexable":true}},
    alias: TestYNc71IId3XMeta?.alias || [],
    redirect: TestYNc71IId3XMeta?.redirect || undefined,
    component: () => import("/views/test/Test.vue").then(m => m.default || m)
  },
  {
    name: TestStoryblokn6diARh4CFMeta?.name ?? "test-storyblok",
    path: TestStoryblokn6diARh4CFMeta?.path ?? "/:lang([a-z]{2}-[a-z]{2})/test/storyblok/:page",
    meta: {...(TestStoryblokn6diARh4CFMeta || {}), ...{"isNotIndexable":true}},
    alias: TestStoryblokn6diARh4CFMeta?.alias || [],
    redirect: TestStoryblokn6diARh4CFMeta?.redirect || undefined,
    component: () => import("/views/test/TestStoryblok.vue").then(m => m.default || m)
  },
  {
    name: StoreFinderrW0MMkGJqlMeta?.name ?? "store-finder",
    path: StoreFinderrW0MMkGJqlMeta?.path ?? "/:lang([a-z]{2}-[a-z]{2})/storefinder",
    meta: {...(StoreFinderrW0MMkGJqlMeta || {}), ...{"cacheMaxAgeInSeconds":86400}},
    alias: StoreFinderrW0MMkGJqlMeta?.alias || [],
    redirect: StoreFinderrW0MMkGJqlMeta?.redirect || undefined,
    component: () => import("/views/storeFinder/StoreFinder.vue").then(m => m.default || m)
  },
  {
    name: ShoppingAppointmentLEie1yE7ZXMeta?.name ?? "shopping-appointment",
    path: ShoppingAppointmentLEie1yE7ZXMeta?.path ?? "/:lang([a-z]{2}-[a-z]{2})/shopping-appointment",
    meta: {...(ShoppingAppointmentLEie1yE7ZXMeta || {}), ...{"cacheMaxAgeInSeconds":86400}},
    alias: ShoppingAppointmentLEie1yE7ZXMeta?.alias || [],
    redirect: ShoppingAppointmentLEie1yE7ZXMeta?.redirect || undefined,
    component: () => import("/views/shoppingAppointment/ShoppingAppointment.vue").then(m => m.default || m)
  },
  {
    name: Memberseiv0Lyyye4Meta?.name ?? "members",
    path: Memberseiv0Lyyye4Meta?.path ?? "/:lang([a-z]{2}-[a-z]{2})/formembers",
    meta: {...(Memberseiv0Lyyye4Meta || {}), ...{"cacheMaxAgeInSeconds":86400}},
    alias: Memberseiv0Lyyye4Meta?.alias || [],
    redirect: Memberseiv0Lyyye4Meta?.redirect || undefined,
    component: () => import("/views/members/Members.vue").then(m => m.default || m)
  },
  {
    name: GlobalE3qNHnQqBlwMeta?.name ?? "checkout-global-e",
    path: GlobalE3qNHnQqBlwMeta?.path ?? "/:lang([a-z]{2}-[a-z]{2})/checkout/global-e",
    meta: {...(GlobalE3qNHnQqBlwMeta || {}), ...{"isNotIndexable":true,"cacheMaxAgeInSeconds":86400,"hideSuperbanner":true,"hideHeader":true,"hideFooter":true,"disableChat":true}},
    alias: GlobalE3qNHnQqBlwMeta?.alias || [],
    redirect: GlobalE3qNHnQqBlwMeta?.redirect || undefined,
    component: () => import("/views/checkout/GlobalE.vue").then(m => m.default || m)
  },
  {
    name: StoreAppPageuymq5iByMfMeta?.name ?? "store-app",
    path: StoreAppPageuymq5iByMfMeta?.path ?? "/:lang([a-z]{2}-[a-z]{2})/store-app/:slug(.*)",
    meta: {...(StoreAppPageuymq5iByMfMeta || {}), ...{"isNotIndexable":true,"cacheMaxAgeInSeconds":86400,"hideSuperbanner":true,"hideHeader":true,"hideFooter":true}},
    alias: StoreAppPageuymq5iByMfMeta?.alias || [],
    redirect: StoreAppPageuymq5iByMfMeta?.redirect || undefined,
    component: () => import("/views/storeApp/StoreAppPage.vue").then(m => m.default || m)
  },
  {
    name: NewsletterRegistrationV2gxCb12rGYAMeta?.name ?? "newsletter-registration",
    path: NewsletterRegistrationV2gxCb12rGYAMeta?.path ?? "/:lang([a-z]{2}-[a-z]{2})/newsletter/registration",
    meta: {...(NewsletterRegistrationV2gxCb12rGYAMeta || {}), ...{"cacheMaxAgeInSeconds":86400}},
    alias: NewsletterRegistrationV2gxCb12rGYAMeta?.alias || [],
    redirect: NewsletterRegistrationV2gxCb12rGYAMeta?.redirect || undefined,
    component: () => import("/views/newsletter/NewsletterRegistrationV2.vue").then(m => m.default || m)
  },
  {
    name: NewsletterRegistrationV1ySJBmDgAGaMeta?.name ?? "newsletter-registration-v1",
    path: NewsletterRegistrationV1ySJBmDgAGaMeta?.path ?? "/:lang([a-z]{2}-[a-z]{2})/newsletter/:type(raffle|advent)",
    meta: {...(NewsletterRegistrationV1ySJBmDgAGaMeta || {}), ...{"cacheMaxAgeInSeconds":86400}},
    alias: NewsletterRegistrationV1ySJBmDgAGaMeta?.alias || [],
    redirect: NewsletterRegistrationV1ySJBmDgAGaMeta?.redirect || undefined,
    component: () => import("/views/newsletter/NewsletterRegistrationV1.vue").then(m => m.default || m)
  },
  {
    name: NewsletterConfirmationSFXSjVeGw5Meta?.name ?? "newsletter-confirmation",
    path: NewsletterConfirmationSFXSjVeGw5Meta?.path ?? "/:lang([a-z]{2}-[a-z]{2})/newsletter/confirmation",
    meta: {...(NewsletterConfirmationSFXSjVeGw5Meta || {}), ...{"isNotIndexable":true,"cacheMaxAgeInSeconds":86400}},
    alias: NewsletterConfirmationSFXSjVeGw5Meta?.alias || [],
    redirect: NewsletterConfirmationSFXSjVeGw5Meta?.redirect || undefined,
    component: () => import("/views/newsletter/NewsletterConfirmation.vue").then(m => m.default || m)
  },
  {
    name: PreferenceCenterSPSm87R59aMeta?.name ?? "newsletter-preference-center",
    path: PreferenceCenterSPSm87R59aMeta?.path ?? "/:lang([a-z]{2}-[a-z]{2})/newsletter/preference-center",
    meta: {...(PreferenceCenterSPSm87R59aMeta || {}), ...{"isNotIndexable":true,"cacheMaxAgeInSeconds":86400}},
    alias: PreferenceCenterSPSm87R59aMeta?.alias || [],
    redirect: PreferenceCenterSPSm87R59aMeta?.redirect || undefined,
    component: () => import("/views/newsletter/PreferenceCenter.vue").then(m => m.default || m)
  },
  {
    name: StaticPage9cOFfHk3LgMeta?.name ?? "static",
    path: StaticPage9cOFfHk3LgMeta?.path ?? "/:lang([a-z]{2}-[a-z]{2})/static/:slug(.*)",
    meta: {...(StaticPage9cOFfHk3LgMeta || {}), ...{"cacheMaxAgeInSeconds":86400}},
    alias: StaticPage9cOFfHk3LgMeta?.alias || [],
    redirect: StaticPage9cOFfHk3LgMeta?.redirect || undefined,
    component: () => import("/views/content/StaticPage.vue").then(m => m.default || m)
  },
  {
    name: MarketingPagerTWWkW1FamMeta?.name ?? "marketing",
    path: MarketingPagerTWWkW1FamMeta?.path ?? "/:lang([a-z]{2}-[a-z]{2})/inspiration/:slug([^.]*)",
    meta: {...(MarketingPagerTWWkW1FamMeta || {}), ...{"cacheMaxAgeInSeconds":86400,"disableActiveCategoryReset":true}},
    alias: MarketingPagerTWWkW1FamMeta?.alias || [],
    redirect: MarketingPagerTWWkW1FamMeta?.redirect || undefined,
    component: () => import("/views/content/MarketingPage.vue").then(m => m.default || m)
  },
  {
    name: Category5DQDcCpHAcMeta?.name ?? "search",
    path: Category5DQDcCpHAcMeta?.path ?? "/:lang([a-z]{2}-[a-z]{2})/search",
    meta: {...(Category5DQDcCpHAcMeta || {}), ...{"isNotIndexable":true,"cacheMaxAgeInSeconds":86400}},
    alias: Category5DQDcCpHAcMeta?.alias || [],
    redirect: Category5DQDcCpHAcMeta?.redirect || undefined,
    component: () => import("/views/category/Category.vue").then(m => m.default || m)
  },
  {
    name: WishlistxS6mD6PsuMMeta?.name ?? "wishlist",
    path: WishlistxS6mD6PsuMMeta?.path ?? "/:lang([a-z]{2}-[a-z]{2})/wishlist",
    meta: {...(WishlistxS6mD6PsuMMeta || {}), ...{"cacheMaxAgeInSeconds":86400,"isNotIndexable":true}},
    alias: WishlistxS6mD6PsuMMeta?.alias || [],
    redirect: WishlistxS6mD6PsuMMeta?.redirect || undefined,
    component: () => import("/views/wishlist/Wishlist.vue").then(m => m.default || m)
  },
  {
    name: CartAaB4tRYXFgMeta?.name ?? "cart",
    path: CartAaB4tRYXFgMeta?.path ?? "/:lang([a-z]{2}-[a-z]{2})/cart",
    meta: {...(CartAaB4tRYXFgMeta || {}), ...{"cacheMaxAgeInSeconds":86400,"isNotIndexable":true}},
    alias: CartAaB4tRYXFgMeta?.alias || [],
    redirect: CartAaB4tRYXFgMeta?.redirect || undefined,
    component: () => import("/views/cart/Cart.vue").then(m => m.default || m)
  },
  {
    name: StoreReservationjOufpsF1FBMeta?.name ?? "store-reservation",
    path: StoreReservationjOufpsF1FBMeta?.path ?? "/:lang([a-z]{2}-[a-z]{2})/store-reservation",
    meta: {...(StoreReservationjOufpsF1FBMeta || {}), ...{"cacheMaxAgeInSeconds":86400,"isNotIndexable":true}},
    alias: StoreReservationjOufpsF1FBMeta?.alias || [],
    redirect: StoreReservationjOufpsF1FBMeta?.redirect || undefined,
    component: () => import("/views/storeReservation/StoreReservation.vue").then(m => m.default || m)
  },
  {
    name: EmptyLKulokmmacMeta?.name ?? "redirect",
    path: EmptyLKulokmmacMeta?.path ?? "/:lang([a-z]{2}-[a-z]{2})/:redirect(to-pid|to-cgid|to-page|to-external)/:target(.*)",
    meta: {...(EmptyLKulokmmacMeta || {}), ...{"isNotIndexable":true,"cacheMaxAgeInSeconds":86400}},
    alias: EmptyLKulokmmacMeta?.alias || [],
    redirect: EmptyLKulokmmacMeta?.redirect || undefined,
    component: () => import("/views/index/Empty.vue").then(m => m.default || m)
  },
  {
    name: Category5DQDcCpHAcMeta?.name ?? "category-page",
    path: Category5DQDcCpHAcMeta?.path ?? "/:lang([a-z]{2}-[a-z]{2})/:path(.*)",
    meta: {...(Category5DQDcCpHAcMeta || {}), ...{"cacheMaxAgeInSeconds":86400,"hideSelfReferencingCanonical":true,"disableActiveCategoryReset":true}},
    alias: Category5DQDcCpHAcMeta?.alias || [],
    redirect: Category5DQDcCpHAcMeta?.redirect || undefined,
    component: () => import("/views/category/Category.vue").then(m => m.default || m)
  },
  {
    name: EmptyLKulokmmacMeta?.name ?? "not-found",
    path: EmptyLKulokmmacMeta?.path ?? "/:path(.*)*",
    meta: {...(EmptyLKulokmmacMeta || {}), ...{"isNotIndexable":true}},
    alias: EmptyLKulokmmacMeta?.alias || [],
    redirect: EmptyLKulokmmacMeta?.redirect || undefined,
    component: () => import("/views/index/Empty.vue").then(m => m.default || m)
  },
  {
    name: WalletLandingb4uBgQbLsWMeta?.name ?? "wallet",
    path: WalletLandingb4uBgQbLsWMeta?.path ?? "/:lang([a-z]{2}-[a-z]{2})/wallet",
    meta: {...(WalletLandingb4uBgQbLsWMeta || {}), ...{"isNotIndexable":true,"cacheMaxAgeInSeconds":86400,"hideSuperbanner":true,"hideHeader":true,"hideFooter":true}},
    alias: WalletLandingb4uBgQbLsWMeta?.alias || [],
    redirect: WalletLandingb4uBgQbLsWMeta?.redirect || undefined,
    component: () => import("/views/wallet/WalletLanding.vue").then(m => m.default || m)
  }
]