import type { Birthday, CustomerGroup } from '@/types/customer';
import type {
  BffNewsletterThemeKey,
  BffApiAccountMeGet,
  BffApiAccountMePut,
  BffApiBonusVoucherStepsGetResponse,
} from '@/types/bff';
import type { ApiResponse } from '@/types/apiInit';

const localCache: {
  customerGroups?: CustomerGroup[];
} = {
  customerGroups: [],
};

export function customerModel(response: ApiResponse<BffApiAccountMeGet | BffApiAccountMePut> | null) {
  const customer = response?.data && 'customer' in response?.data ? response?.data.customer : undefined;

  return {
    hasError(): boolean {
      return response?.data?.code !== 'OK';
    },

    isInitialized(): boolean {
      return customer !== undefined;
    },

    getEmail(): string {
      return customer?.email ?? '';
    },

    getTitle(): string {
      if (!customer?.academicTitle || customer?.academicTitle === constants.COMMON.TITLE.NONE) {
        return '';
      }
      return customer?.academicTitle;
    },

    getPhoneNumber(): string {
      return customer?.phoneNumber ?? '';
    },

    getCurrentBonusPoints(): number {
      return customer?.bonusPoints ?? 0;
    },

    getMemberSinceDate(): string {
      return customer?.memberRegistrationDate ?? '';
    },

    getRegistrationDate(): string {
      return customer?.createdAt ?? '';
    },

    getBonusAccountNumber(): string {
      return customer?.bonusAccountNumber ?? '';
    },

    getBirthday(): Birthday {
      const birthday = customer?.dateOfBirth ?? '';
      let year = '';
      let month = '';
      let day = '';

      if (birthday !== '') {
        [year, month, day] = birthday.split('-');
        return {
          year,
          month: parseInt(month).toString(),
          day: parseInt(day).toString(),
        };
      }

      return {
        year,
        month,
        day,
      };
    },

    getFirstName(): string {
      return customer?.firstName ?? '';
    },

    getFavoriteStoreId(): string {
      return customer?.favoriteStoreId ?? '';
    },

    getLastName(): string {
      return customer?.lastName ?? '';
    },

    getFullName(): string {
      return [this.getFirstName(), this.getLastName()].join(' ');
    },

    getGender() {
      return customer?.gender ?? '';
    },

    isGuest(): boolean {
      return !customer;
    },

    getCommercetoolsCustomerGroupId() {
      return customer?.customerGroup?.id ?? '';
    },

    getCustomerGroups(): CustomerGroup[] {
      if (localCache.customerGroups?.length) {
        return localCache.customerGroups;
      }

      const groups: CustomerGroup[] = [];

      if (this.hasError() || this.isGuest()) {
        return [constants.CUSTOMER_GROUPS.GUEST];
      }
      groups.push(constants.CUSTOMER_GROUPS.MEMBER);

      const hasBonus = this.hasBonus();
      const currentBonusPoints = this.getCurrentBonusPoints();

      // Hint: do not combine conditions, otherwise it will be hard to compare with business/concepts
      if (hasBonus && !this.isOptInMail()) {
        groups.push(constants.CUSTOMER_GROUPS.MEMBER_NO_MAIL_OPT_IN);
      }

      if (hasBonus && currentBonusPoints > 0 && !this.isOptInPost()) {
        groups.push(constants.CUSTOMER_GROUPS.MEMBER_NO_POST_OPT_IN);
      }

      if (hasBonus && currentBonusPoints > 0 && this.isOptInPost()) {
        groups.push(constants.CUSTOMER_GROUPS.MEMBER_WITH_POST_OPT_IN);
      }

      const daysAgo = 30;
      const compareTime: number = Date.now() - daysAgo * 24 * 60 * 60 * 1000;
      const hadRecentBonusPointsRedemption: boolean =
        new Date(this.getLastBonusRedemptionDate() || Date.now()).getTime() > compareTime;
      if (hasBonus && currentBonusPoints <= 0 && hadRecentBonusPointsRedemption) {
        groups.push(constants.CUSTOMER_GROUPS.MEMBER_BONUSPOINT_RESET);
      }

      if (!this.getFavoriteStoreId()) {
        groups.push(constants.CUSTOMER_GROUPS.MEMBER_NO_FAVORITE_STORE);
      }

      if (new Date(this.getRegistrationDate()) > new Date('2019-03-19')) {
        if (hasBonus) {
          groups.push(constants.CUSTOMER_GROUPS.MEMBER_WITH_BONUS_MIGRATION);
        } else {
          groups.push(constants.CUSTOMER_GROUPS.MEMBER_NO_BONUS_MIGRATION);
        }
      }

      localCache.customerGroups = groups;
      return localCache.customerGroups;
    },

    getLastBonusRedemptionDate(): string {
      return customer?.lastBonusPointsRedemptionDate ?? '';
    },

    hasInterestInMen() {
      return customer?.isInterestedInMensNewsletter === true;
    },

    hasInterestInWomen() {
      return customer?.isInterestedInWomensNewsletter === true;
    },

    isOptInMail(): boolean {
      return customer?.isOptedInForEmail === true;
    },

    isOptInPost(): boolean {
      return customer?.isOptedInForPost === true;
    },

    getOptInThemes(): BffNewsletterThemeKey[] {
      return customer?.newsletterThemes ?? [];
    },

    hasBonus(): boolean {
      return customer?.isCollectingBonusPoints === true;
    },

    // CRM id - (contact number)
    getCustomerNumber(): string {
      return customer?.customerNumber || '';
    },

    hasSyncCRM(): boolean {
      return Boolean(this.getCustomerNumber());
    },

    getPointsToNextStep(pointSteps: BffApiBonusVoucherStepsGetResponse): number {
      const customerPoints = this.getCurrentBonusPoints();
      const pointsNext = pointSteps.bonusVoucherSteps.reduce((previous: number, current) => {
        return customerPoints >= previous && customerPoints < current.minBonusPoints
          ? current.minBonusPoints
          : previous;
      }, 0);
      return pointsNext > 0 ? pointsNext - customerPoints : 0;
    },

    getLastUsedPaymentMethod(): string {
      return customer?.lastUsedPaymentMethod ?? '';
    },

    getLastUsedShippingMethodKey(): string {
      return customer?.lastUsedShippingMethodKey ?? '';
    },

    getFirstOrderDate() {
      return customer?.firstOrderDate ?? '';
    },

    hasAppleWallet() {
      return customer?.hasAppleWallet === true;
    },

    hasGoogleWallet() {
      return customer?.hasGoogleWallet === true;
    },

    getCrmUserlId() {
      return customer?.externalId ?? '';
    },

    getCommercetoolsUserId() {
      return customer?.id ?? '';
    },

    isEmployeeAccount() {
      return customer?.isEmployeeAccount === true;
    },

    getDebugData() {
      return response;
    },
  };
}
