import { logError } from '@mop/shared/utils/logger';
import { isClient } from '@mop/shared/utils/util';
import { localStorageSet, localStorageRemove } from '@mop/shared/utils/localStorage';
import type { RouteLocationNormalizedLoaded } from 'vue-router';
import { isLocalhost } from '@/utils/util';
import type { NuxtApp } from '#app';
import {
  createApiCms,
  createApiLocal,
  createApiCrmMiddleware,
  createApiCommercetools,
  createApiEpoq,
  createApiWallet,
  createApiBff,
} from '@/api';
import { countryConfigModel } from '@/models';
import type {
  CountryConfigResponseData,
  CountryAbTestConfigResponseData,
  CountryCategoriesConfigResponseData,
  CountryProductsConfigResponseData,
  CountryPopularityFlagConfigResponseData,
} from '@/types/countryConfig';
import { headersMiddleware } from '@/routerMiddleware/headers';

export default defineNuxtPlugin(async (nuxtApp) => {
  const app = nuxtApp.vueApp.$nuxt as NuxtApp;
  if (!app.$mopI18n) {
    return;
  }

  const isStoryblokLivePreview = app.$storyblokLivePreview.isEnabled;
  const { lang, country, commercetoolsCountry, currency } = app.$mopI18n;
  const config = useRuntimeConfig();
  const route = useRoute();
  const { cmsLanguage, cmsFallbackLanguage } = getCmsLanguages(app, route);

  if (isClient) {
    app.$config.public.HOME_URL = app.$config.public.HOME_URL || app.$mopI18n.localePath('');
  }

  nuxtApp.provide('apiLocal', createApiLocal());
  nuxtApp.provide(
    'apiCms',
    createApiCms({
      cmsApiKey: isStoryblokLivePreview
        ? config.public.STORYBLOK_API_KEY_PREVIEW
        : config.public.STORYBLOK_API_KEY_PUBLISHED,
      cmsVersion: isStoryblokLivePreview ? 'draft' : 'published',
      cmsLanguage,
      cmsFallbackLanguage,
      cmsRelease: String(route.query._storyblok_release),
    }),
  );

  nuxtApp.provide(
    'apiCrmMiddleware',
    createApiCrmMiddleware({
      url: config.public.CRM_URL,
    }),
  );

  const commercetoolsMiddlewareApiClient = createApiBff({
    // We need proxied URL only for local development in order to properly read cookies on the client side, on backend proxy is not working due to certificates
    url: isClient && isLocalhost ? config.public.CT_AWS_BFF_PROXIED_URL : config.public.CT_AWS_BFF_URL,
    lang,
    country,
    commercetoolsCountry,
    currency,
  });

  nuxtApp.provide('apiBff', commercetoolsMiddlewareApiClient);

  nuxtApp.provide(
    'apiCommercetools',
    await createApiCommercetools(
      {
        host: config.public.CT_HOST,
        projectKey: config.public.CT_PROJECT_KEY,
        middlewareApiClient: commercetoolsMiddlewareApiClient,
        lang,
        country,
        commercetoolsCountry,
        currency,
        locale: `${lang}-${commercetoolsCountry}`,
      },
      app,
    ),
  );

  const { cmsGlobalStoryListModelRef, initGlobalComponents } = useMopCmsGlobalComponents();
  // Only add independent stuff, for example searchRootCategories can't depend on country config settings

  const { searchRootCategories, rootCategoryListRef } = useMopRootCategories();
  const { initAvailableCartDiscounts } = useMopCartDiscounts();
  const { initAvailableProductDiscounts } = useMopProductDiscounts();
  const { initSearchableAttributes } = useMopSearchableAttributes();

  await Promise.all([
    initGlobalComponents(),
    searchRootCategories(),
    initAvailableCartDiscounts(),
    initAvailableProductDiscounts(),
    initSearchableAttributes(),
  ]);

  const maintenanceStory = cmsGlobalStoryListModelRef.value.getStoryModelByName(
    constants.STORYBLOK.GLOBAL_STORY_NAMES.MAINTENANCE,
  )!;
  const isDebugCookieEnabled = Boolean(app.$cookie.get(constants.COOKIE.DEBUG));
  const isMaintenance = maintenanceStory?.getAttribute('isActive') && !isDebugCookieEnabled;

  if (isMaintenance) {
    // According to: https://developers.google.com/search/blog/2011/01/how-to-deal-with-planned-site-downtime
    const event = useRequestEvent();
    setResponseStatus(event, 503);
  }

  nuxtApp.provide('maintenance', {
    isActive: isMaintenance,
    story: maintenanceStory,
  });

  nuxtApp.provide('rootCategories', rootCategoryListRef.value);

  nuxtApp.provide(
    'mopConfig',
    countryConfigModel(
      cmsGlobalStoryListModelRef.value
        .getStoryModelByName(constants.STORYBLOK.GLOBAL_STORY_NAMES.SHOP_PREFERENCES)
        ?.getResponse() as unknown as CountryConfigResponseData,
      cmsGlobalStoryListModelRef.value
        .getStoryModelByName(constants.STORYBLOK.GLOBAL_STORY_NAMES.SHOP_PREFERENCES_AB_TEST)
        ?.getResponse() as unknown as CountryAbTestConfigResponseData,
      cmsGlobalStoryListModelRef.value
        .getStoryModelByName(constants.STORYBLOK.GLOBAL_STORY_NAMES.SHOP_PREFERENCES_CATEGORIES)
        ?.getResponse() as unknown as CountryCategoriesConfigResponseData,
      cmsGlobalStoryListModelRef.value
        .getStoryModelByName(constants.STORYBLOK.GLOBAL_STORY_NAMES.SHOP_PREFERENCES_PRODUCTS)
        ?.getResponse() as unknown as CountryProductsConfigResponseData,
      cmsGlobalStoryListModelRef.value
        .getStoryModelByName(constants.STORYBLOK.GLOBAL_STORY_NAMES.SHOP_PREFERENCES_POPULARITY_FLAGS)
        ?.getResponse() as unknown as CountryPopularityFlagConfigResponseData,
    ),
  );

  if (app.$mopConfig.getEpoqTenantId() && isClient) {
    nuxtApp.provide(
      'apiEpoq',
      createApiEpoq({
        url: config.public.EPOQ_URL.replace('{TENANT_ID}', app.$mopConfig.getEpoqTenantId()),
      }),
    );
  }

  if (isClient) {
    nuxtApp.provide(
      'apiWallet',
      createApiWallet({
        url: config.public.WALLET_URL,
      }),
    );
  }

  addRouteMiddleware(async (to) => {
    if (!isClient) {
      headersMiddleware(to);
    }
    if (await redirectHandler(to)) {
      // stops furhter response processing, otherwise error 500 is very likely
      return false;
    }
  });

  if (isClient) {
    await initCustomer();
    useMopAffiliateTracking().initAffiliate();
  }
});

async function initCustomer() {
  const { customerModelRef, handleCustomer } = useMopCustomer();
  await handleCustomer();

  if (customerModelRef.value.isGuest()) {
    localStorageRemove(constants.LOCAL_STORAGE.AB_TASTY);
  } else {
    localStorageSet(constants.LOCAL_STORAGE.AB_TASTY, customerModelRef.value.getCustomerNumber());
  }
}

function getCmsLanguages(nuxtApp: NuxtApp, route: RouteLocationNormalizedLoaded) {
  const STORYBLOK_DEFAULT_PAREMETER_LANGUAGE_KEY = 'default';
  try {
    let cmsLanguage: string = nuxtApp.$mopI18n.locale;
    let cmsFallbackLanguage: string = nuxtApp.$mopI18n.cmsFallbackLanguage || STORYBLOK_DEFAULT_PAREMETER_LANGUAGE_KEY;
    const storyblokLanguageParameter = route.query._storyblok_lang;
    if (storyblokLanguageParameter) {
      cmsLanguage = String(storyblokLanguageParameter);
      if (cmsLanguage === STORYBLOK_DEFAULT_PAREMETER_LANGUAGE_KEY) {
        cmsLanguage = defaultStoryblokLanguage;
        cmsFallbackLanguage = '';
      }
    }

    if (cmsLanguage === cmsFallbackLanguage) {
      cmsFallbackLanguage = '';
    }

    return {
      cmsLanguage,
      cmsFallbackLanguage,
    };
  } catch (error) {
    logError(error);
  }
  return {
    cmsLanguage: nuxtApp.$mopI18n.locale,
    cmsFallbackLanguage: STORYBLOK_DEFAULT_PAREMETER_LANGUAGE_KEY,
  };
}
