<script setup lang="ts">
defineOptions({
  name: 'MopToastsWrapper',
});

const { toastsListRef } = useMopToastsClient();
</script>

<template>
  <div class="toasts-wrapper">
    <Ui2Toast
      v-for="toast in toastsListRef"
      :key="toast.content"
      v-model="toast.modelValue"
      :title="toast.title"
      :auto-close-delay="toast.autoCloseDelay"
      :show-icon="toast.showIcon"
      :show-close-button="toast.showCloseButton"
      :status="toast.status"
    >
      {{ toast.content }}
      <template v-if="toast.action" #action>
        <span v-html="toast.action" />
      </template>
    </Ui2Toast>
  </div>
</template>

<style lang="scss" scoped>
.toasts-wrapper {
  @include z(layers, above-layer);

  position: fixed;
  top: $space-24;
  right: $space-24;

  display: flex;
  flex-direction: column;
  gap: $space-24;

  @include v2-apply-upto(mobile) {
    left: $space-8;
    right: $space-8;
    top: $space-8;
    gap: $space-8;
  }
}
</style>
