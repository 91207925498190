import { isClient } from '@mop/shared/utils/util';
import { securedWrap } from '@mop/shared/utils/securedWrap';
import { sessionStorageGet, sessionStorageRemove, sessionStorageSet } from '@mop/shared/utils/sessionStorage';
import type { GtmProductTrackingParams, EventParams, GtmProductListType } from '@/types/gtm';
import type {
  TrackingPageViewParams,
  TrackingEventParams,
  TrackingEngagementParams,
  TrackingViewParams,
} from '@/types/tracking';
const reportedAbTastyTests: string[] = [];

type AbTastyTest = {
  name: string;
  variationName: string;
  variationID: string;
};

export default function useMopTrackingClient() {
  const { $mopI18n, $cookie, $abTestServerSide } = useNuxtApp();

  function reportPageView(value: TrackingPageViewParams) {
    if (!isClient) {
      return;
    }

    if (value?.gtm?.data) {
      const { customerModelRef } = useMopCustomer();
      const { trackPageView, trackView, trackCartContent } = useMopGtm();
      const { cartModelRef } = useMopCartClient();
      value.gtm.data.userId = String(
        value.gtm.data.userId ||
          customerModelRef.value.getCustomerNumber() ||
          sessionStorageGet(constants.SESSION_STORAGE.USER_ID_FROM_GET_PARAMS) ||
          '',
      );
      value.gtm.data.pageCountryCode = $mopI18n.country.toUpperCase();
      value.gtm.data.pageLanguageCode = $mopI18n.locale.replace('-', '_');
      value.gtm.data.privacyOptOutPerformance = parseInt(
        $cookie.get(constants.COOKIE.PRIVACY_OPT_OUT_PERFORMANCE) || '0',
      );
      value.gtm.data.globalE = $mopI18n.isGlobalE;
      value.gtm.data.abTestName = $abTestServerSide.abTestName;
      value.gtm.data.abTestVariant = $abTestServerSide.abTestVariant;
      trackPageView(value.gtm.data);
      trackView(useRoute().fullPath);
      trackAbTasty();
      trackCartContent(cartModelRef.value.getLineItems());
    }
  }

  function trackAbTasty() {
    getUnreportedABTastyTests().forEach((abTastyTest) => {
      reportEngagement({
        gtm: {
          type: 'Click',
          data: {
            custom: {
              event: 'abTasty',
              category: abTastyTest.name,
              label: `${abTastyTest.variationName}_${abTastyTest.variationID}`,
            },
          },
        },
      });
    });
  }

  function getUnreportedABTastyTests(): AbTastyTest[] {
    if (typeof window.ABTasty === 'undefined') {
      return [];
    }
    let testsOnPage: any = {};
    try {
      testsOnPage = window.ABTasty?.getTestsOnPage() || {};
    } catch (err) {
      return [];
    }
    const unreportedTests: AbTastyTest[] = [];
    Object.keys(testsOnPage).forEach((testKey) => {
      if (reportedAbTastyTests.includes(testKey)) {
        return;
      }
      const { name, variationName, variationID } = testsOnPage[testKey];
      unreportedTests.push({
        name: (name || '').replace(/ /g, ''),
        variationName: (variationName || '').replace(/ /g, ''),
        variationID,
      });
      reportedAbTastyTests.push(testKey);
    });
    return unreportedTests;
  }

  function reportEvent(value: TrackingEventParams) {
    if (!isClient) {
      return;
    }

    if (value?.gtm?.data.purchase !== undefined) {
      const data = value.gtm.data.purchase;
      data.products = data.products.map((data) => {
        appendGtmListType(data, true);
        return data;
      });
      useMopGtm().trackPurchase(value.gtm.data.purchase);
    }

    if (value?.gtm?.data.cartContent !== undefined) {
      useMopGtm().trackCartContent(value.gtm.data.cartContent.items);
    }

    if (value?.gtm?.data.checkout !== undefined) {
      const data = value.gtm.data.checkout;
      data.products = data.products.map((data) => {
        appendGtmListType(data);
        return data;
      });
      useMopGtm().trackCheckout(value.gtm.data.checkout);
    }

    if (value?.gtm?.data.ayCheckout !== undefined) {
      const data = value.gtm.data.ayCheckout;
      data.products = data.products.map((data: any) => {
        appendGtmListType(data);
        return data;
      });
      useMopGtm().trackAyCheckout(value.gtm.data.ayCheckout);
    }
  }

  function setGtmListValueInSessionStorage(data: GtmProductTrackingParams) {
    if (data?.product && data.list) {
      sessionStorageSet(getGtmListTypeKey(data), data.list);
    }
  }

  function reportEngagement(value: TrackingEngagementParams) {
    if (!isClient) {
      return;
    }

    if (value?.gtm?.data) {
      let data: GtmProductTrackingParams;
      switch (value.gtm.type) {
        case 'ProductClick':
          data = value.gtm.data.product as GtmProductTrackingParams;
          setGtmListValueInSessionStorage(data);
          useMopGtm().trackProductClick(data);
          break;
        case 'AddToCart':
          data = value.gtm.data.product as GtmProductTrackingParams;
          appendGtmListType(data);
          useMopGtm().trackAddToCartClick(data);
          break;
        case 'RemoveFromCart':
          data = value.gtm.data.product as GtmProductTrackingParams;
          appendGtmListType(data);
          useMopGtm().trackRemoveFromCartClick(data);
          break;
        case 'Click':
          useMopGtm().trackClick(value.gtm.data.custom as EventParams);
          break;
        default:
          break;
      }
    }
  }

  function reportView(value: TrackingViewParams) {
    if (!isClient) {
      return;
    }

    if (value.gtm?.type) {
      switch (value.gtm.type) {
        case 'ProductImpression':
          useMopGtm().trackProductImpression(value.gtm.data.product as GtmProductTrackingParams);
          break;
        case 'ProductDetail':
          // eslint-disable-next-line no-case-declarations
          const data = value.gtm.data.product as GtmProductTrackingParams;
          appendGtmListType(data);
          useMopGtm().trackProduct(data);
          break;
        default:
          break;
      }
    }
  }

  function getGtmListTypeKey(data: GtmProductTrackingParams) {
    return data?.product && `gtm_list_type_${data.product.getMopId()}`;
  }

  function appendGtmListType(data: GtmProductTrackingParams, deleteSessionEntry = false) {
    const gtmTypeList: GtmProductListType = (data?.product &&
      sessionStorageGet(getGtmListTypeKey(data))) as GtmProductListType;
    if (gtmTypeList) {
      data.list = gtmTypeList;
    }
    if (gtmTypeList && deleteSessionEntry) {
      sessionStorageRemove(getGtmListTypeKey(data));
    }
  }

  return securedWrap({
    reportPageView,
    reportEvent,
    reportEngagement,
    reportView,
  });
}
