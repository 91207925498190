import type {
  ClientResponse,
  ProductProjectionPagedSearchResponse,
  ProductPagedSearchResponse,
} from '@commercetools/platform-sdk';
import type { ApiCommercetoolsConfig } from '@/types/apiInit';
import type { ProductSearchParams, ProductProjectionSearchParams } from '@/types/product';

// Keep enrichProductResponseWithCategoryKeys and enrichProductProjectionResponseWithCategoryKeys in sync
function enrichProductResponseWithCategoryKeys(
  productResponse: ClientResponse<ProductPagedSearchResponse>,
  categories: Record<string, string>,
) {
  for (let productIndex = 0; productIndex < productResponse.body.results.length; productIndex++) {
    for (
      let categoryIndex = 0;
      categoryIndex < (productResponse.body.results[productIndex].productProjection?.categories.length ?? 0);
      categoryIndex++
    ) {
      // @ts-ignore Hacky way to rewrite readonly property
      productResponse.body.results[productIndex].productProjection.categories[categoryIndex].obj = {
        key:
          categories[productResponse.body.results[productIndex].productProjection!.categories[categoryIndex].id] ?? '',
      };
    }
  }
  return productResponse;
}

// Keep enrichProductResponseWithCategoryKeys and enrichProductProjectionResponseWithCategoryKeys in sync
function enrichProductProjectionResponseWithCategoryKeys(
  productResponse: ClientResponse<ProductProjectionPagedSearchResponse>,
  categories: Record<string, string>,
) {
  for (let productIndex = 0; productIndex < productResponse.body.results.length; productIndex++) {
    for (
      let categoryIndex = 0;
      categoryIndex < (productResponse.body.results[productIndex].categories.length ?? 0);
      categoryIndex++
    ) {
      // @ts-ignore Hacky way to rewrite readonly property
      productResponse.body.results[productIndex].categories[categoryIndex].obj = {
        key: categories[productResponse.body.results[productIndex].categories[categoryIndex].id] ?? '',
      };
    }
  }
  return productResponse;
}

// Needed on every search call
// For projection search -> added already in composables
function getBaseSearchQueryParams(country: string, currency: string) {
  const countryValue = country.toUpperCase();
  return [
    {
      exact: {
        field: 'variants.prices.country',
        value: countryValue,
      },
    },
    {
      exact: {
        field: 'variants.prices.currencyCode',
        value: currency,
      },
    },
    {
      exact: {
        field: 'variants.attributes.countrySalePermission',
        fieldType: 'set_text',
        value: countryValue,
      },
    },
  ];
}

export async function searchProducts(
  apiConfig: ApiCommercetoolsConfig,
  searchParams: ProductSearchParams,
  categoryKeysList = {},
) {
  // @ts-ignore TS issue with commercetools
  if (searchParams.body.query?.and) {
    // @ts-ignore TS issue with commercetools
    searchParams.body.query.and = [
      // @ts-ignore TS issue with commercetools
      ...searchParams.body.query.and,
      ...getBaseSearchQueryParams(apiConfig.commercetoolsCountry, apiConfig.currency),
    ];
  } else if (searchParams.body.query) {
    // @ts-ignore readonly attr
    searchParams.body.query = {
      and: [searchParams.body.query, ...getBaseSearchQueryParams(apiConfig.commercetoolsCountry, apiConfig.currency)],
    };
  } else {
    // @ts-ignore readonly attr
    searchParams.body.query = {
      and: getBaseSearchQueryParams(apiConfig.commercetoolsCountry, apiConfig.currency),
    };
  }
  return enrichProductResponseWithCategoryKeys(
    await apiConfig.apiClient.products().search().post(searchParams).execute(),
    categoryKeysList,
  );
}

// Current needed in search overlay only, because of "fuzzy" search and PDP real time stock updates
export async function productProjectionSearch(
  apiConfig: ApiCommercetoolsConfig,
  searchParams: ProductProjectionSearchParams,
  categoryKeysList = {},
): Promise<ClientResponse<ProductProjectionPagedSearchResponse>> {
  return enrichProductProjectionResponseWithCategoryKeys(
    await apiConfig.apiClient.productProjections().search().get(searchParams).execute(),
    categoryKeysList,
  );
}
