import { isClient } from '@mop/shared/utils/util';
import { securedWrap } from '@mop/shared/utils/securedWrap';
import { wishlistModel } from '@/models';
import type { ProductModel } from '@/types/product';
import type { GtmWishListTarget } from '@/types/gtm';

type LoadingState = {
  init: boolean;
  add: boolean;
  remove: boolean;
  loading: boolean;
};

type WishlistModel = ReturnType<typeof wishlistModel>;

type WishlistComposableStorage = {
  wishlist: Ref<WishlistModel>;
};

export default function useMopWishlistClient() {
  const { reportEngagement } = useMopTrackingClient();
  const storage = initStorage<WishlistComposableStorage>('useWishlist');
  const { createAnonymousSessionIfNotSet, isSessionCreated } = useMopCustomer();
  const loadingRef: Ref<LoadingState> = ref({
    init: false,
    add: false,
    remove: false,
    loading: computed(() => isLoading(loadingRef)),
  });
  const { $apiBff, $gtm2 } = useNuxtApp();
  const wishlistModelRef: Ref<WishlistModel> =
    storage.get('wishlist') ?? storage.saveAndGet('wishlist', ref(wishlistModel(null)));

  async function initWishlist(): Promise<void> {
    if (!isClient || !isSessionCreated()) {
      return;
    }

    loadingRef.value.init = true;
    wishlistModelRef.value = wishlistModel(await $apiBff.getWishlist());
    loadingRef.value.init = false;
  }

  async function addToWishlist(product: ProductModel, gtmTarget: GtmWishListTarget): Promise<void> {
    reportEngagement({
      gtm: {
        type: 'Click',
        data: {
          custom: {
            event: 'AddToWishlist',
            category: getGtmTarget(gtmTarget).toLowerCase(),
            label: product.getMopId(),
          },
        },
      },
    });

    $gtm2.trackEcommerce({
      event: 'add_to_wishlist',
      data: {
        products: [
          {
            product,
            category: getGtmTarget(gtmTarget).toLowerCase(),
          },
        ],
      },
    });

    await createAnonymousSessionIfNotSet();

    const itemExist: boolean = wishlistModelRef.value.isProductInWishlist(product.getId());
    if (itemExist) {
      return;
    }

    loadingRef.value.add = true;
    const updatedWishlist = await $apiBff.addWishlistItem({ productKey: product.getMopId() });
    if (!updatedWishlist?.error) {
      wishlistModelRef.value = wishlistModel(updatedWishlist);
    }
    loadingRef.value.add = false;
  }

  async function removeFromWishlist(product: ProductModel, gtmTarget: GtmWishListTarget): Promise<void> {
    reportEngagement({
      gtm: {
        type: 'Click',
        data: {
          custom: {
            event: 'RemoveFromWishlist',
            category: getGtmTarget(gtmTarget).toLowerCase(),
            label: product.getMopId(),
          },
        },
      },
    });

    loadingRef.value.remove = true;
    const updatedWishlist = await $apiBff.removeWishlistItem({ productKey: product.getMopId() });
    if (!updatedWishlist?.error) {
      wishlistModelRef.value = wishlistModel(updatedWishlist);
    }
    loadingRef.value.remove = false;
  }

  return securedWrap({
    wishlistModelRef,
    initWishlist,
    addToWishlist,
    removeFromWishlist,
    loadingRef,
  });
}

function getGtmTarget(gtmTarget: GtmWishListTarget): string {
  if (/^(detail|wishlist)/gi.test(gtmTarget)) {
    return gtmTarget;
  }
  if (/^(recommendation)/gi.test(gtmTarget)) {
    return 'Recommendation';
  }
  return 'Category';
}
