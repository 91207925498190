import type { ViewportSize } from '@/types/breakpoint';
import type { CmsVisibility } from '@/types/cms';

// Add more options, when neded
export const AB_TEST = {
  QUERY_NAME: 'abtestcookievalue',
  NEWSLETTER: {
    NAME: 'AB-Newsletter-Registration-2024-03',
    CONTROL_VALUE: 'AB-Newsletter-Registration-2024-03|Control',
    VARIANT_VALUE: 'AB-Newsletter-Registration-2024-03|Variant',
  },
  PLP_PRODUCT_TILES: {
    NAME: 'AB-PLP-Producttiles-2024',
    CONTROL_VALUE: 'AB-PLP-Producttiles-2024|Control',
    VARIANT_VALUE: 'AB-PLP-Producttiles-2024|Variant',
  },
} as const;

export default {
  // To not exceed CT expression limit of 50 - Will be enhanced/increased by CT
  MAX_CT_PRODUCT_IDS_PER_REQUEST: 100,
  GLOBAL_CACHE_VALUE_KEY: 'global-components',
  COMMON: {
    MEN: 'm',
    WOMEN: 'f',
    SALUTATION_MEN: 'Mr',
    SALUTATION_WOMEN: 'Ms',
    TITLE: {
      // We need None value because we can't set empty string due to CT <-> CRM sync issue for this type of field
      NONE: 'None',
      DR: 'Dr',
      PROF: 'Prof',
      PROF_DR: 'ProfDr',
    },
  },
  BREAKPOINT: {
    TINY: 'xs' as ViewportSize,
    SMALL: 's' as ViewportSize,
    MEDIUM: 'm' as ViewportSize,
    LARGE: 'l' as ViewportSize,
    EXTRA_LARGE: 'xl' as ViewportSize,
    EXTRA_EXTRA_LARGE: 'xxl' as ViewportSize,
    INFINITE: 'infinite' as ViewportSize,
  },
  LIMITS: {
    CART: {
      MAX_QUANTITY_PER_ITEM: 12,
    },
  },
  TRANSITION_TIME: {
    ENTER: 1000,
    LEAVE: 200,
  },
  PDP_SLUG_PREFIX: 'p/',
  INVENTORY_CALL_THRESHOLD: 12,
  BODY_CLASS_NO_SCROLL: 'body--noscroll',
  BRAND_NAME_DEFAULT: 'MARC O’POLO',
  BRAND_NAME_DEFAULT_SHORT: 'MO’P',
  COOKIE: {
    // storage keys should not make sense to the outside world whoever is checking
    TOP_LEVEL_CATEGORY: 'csmtc', // cookie storage mop top category
    PRIVACY: 'privacy',
    PRIVACY_OPT_OUT_PERFORMANCE: 'privacy-opt-out-performance',
    DEBUG: 'debug',
    LANGUAGE: 'lang',
    GLOBAL_E_DATA: 'GlobalE_Data',
    AB_TEST: 'csmab', // cookie storage for current ab test
    AFFILIATE_TRACKING: 'csmopat', // cookie storage affiliate tracking
    SESSION_HEADER: 'MopSessionHeader', // cookie set from backend for authorization
    SESSION_TYPE: 'MopSessionType', // cookie set from backend for user type: 'anonymous' | 'customer'
  },
  LOCAL_STORAGE: {
    COUNTRY_LOCALISATION: 'lsmopcl', // local storage holding data about customer's geo country
    COUNTRY_SELECTED: 'lsmopcs', // local storage holding previously selected country value
    PREVIEW_CUSTOMER_GROUP: 'lsmoppcg', // local storage holding customer group for storyblok live preview
    ANONYMOUS_RESERVATION: 'lsmopar', // local storage mop anonymous reservation
    RESERVATION: 'lsmopr', // local storage mop reservation
    SUPER_BANNER: 'lsmopsb', // local storage mop super banner
    DISABLED_GIVEAWAY: 'lsmopg', // local storage removed giveaway
    AB_TASTY: 'lsmopabt', // local storage customer id for ab tasty
    EPOQ_SESSION_ID: '7cb54b_session_id',
    VIRTUAL_TRY_ON: 'lsvto', // local storage holding products that customer used virtual try on service
    VIRTUAL_TRY_ON_ACCEPTED: 'lsvtoa', // local storage holding products that customer accepted vto
    ADD_TO_WALLET_TOAST_LAST_SHOWN: 'lsmopatwtls',
    SAIZ_SESSION_ID: 'saiz_session_id',
    HIDE_GUEST_BUYER_ACCOUNT_BADGE: 'lsmophgbab', // local storage for hiding guest buyer account badge
    GUEST_BUYER_HOME_POPUP_SHOWN: 'lsmopgbhps', // local storage for guest buyer home popup shown
    AFTER_AUTH_REDIRECT: 'lsmopaar', // local storage for redirect after login/register
  },
  SESSION_STORAGE: {
    CUSTOMER_JUST_LOGGED_IN: 'ssmopcjli', // session storage customer just logged in
    CHEERING_MESSAGES_SEEN: 'ssmopcms', // session storage for seen cheering messages,
    CLOSED_MEMBERRY_INFOBOX: 'ssmopcmi', // session storage for closed memberry infobox
    REGISTRATION_FORM_DATA: 'ssmoprfd', // session storage for registration form data
    LOGIN_FORM_EMAIL: 'ssmoplfe', // session storage for login form email
    USER_ID_FROM_GET_PARAMS: 'ssmopufgp', // session storage for user id from get params
  },
  SCROLL_DIRECTION_UP: 'up' as string,
  SCROLL_DIRECTION_DOWN: 'down' as string,
  KEY_CODES: {
    ESCAPE: 27,
  },
  ERROR_CODE: {
    NOT_FOUND: 404,
    NEWSLETTER: {
      ALREADY_EXISTS: 409,
    },
  },
  ERROR_MESSAGE: {
    NEWSLETTER: {
      // https://marc-o-polo.atlassian.net/wiki/spaces/DEV/pages/1362003760/Newsletter+API
      OPT_IN_ALREADY_SET: 'optin already set',
      RAFFLE_ADDED: 'raffle added',
      RAFFLE_ALREADY_SET: 'raffle already set',
      ADVENTCALENDERTHEME_ADDED: 'adventcalendartheme added',
      ADVENTCALENDERTHEME_ALREADY_SET: 'adventcalendartheme already set',
    },
  },
  QUERY_PARAMETERS: {
    PRICES: 'prices',
    SEARCH_QUERY: 'q',
    SORT_QUERY: 'sort',
    PAGE_QUERY: 'page',
  },
  META: {
    INDEXABLE: 'index, follow',
    NOT_INDEXABLE: 'noindex, follow',
  },
  REDIRECT_CODES: {
    PERMANENT_REDIRECT: 301,
    FOUND_REDIRECT: 302,
    TEMPORARY_REDIRECT: 307,
  },
  REDIRECT_PATHS: {
    PRODUCT_REDIRECT: 'to-pid',
    CATEGORY_REDIRECT: 'to-cgid',
    PAGE_REDIRECT: 'to-page',
    EXTERNAL_REDIRECT: 'to-external',
  },
  CATEGORY_SLUG: {
    SALE: 'sale',
  },
  VIRTUAL_TOP_CATEGORIES: ['women-junior', 'women-living'] as string[],
  RUNTIME_ENVIRONMENT: {
    PRODUCTION: 'production',
    DEVELOPMENT: 'development',
  },
  ORDER: {
    MOP_WAREHOUSE_GLN: '1967000000101' as string,
    ARVATO_WAREHOUSE_GLN: '4399902067942' as string,
    FALLBACK_IMAGE: 'images/3d44efebb367bb5a607cdb99339c4021.jpg',
  },
  CUSTOMER_GROUPS: {
    GUEST: 'Guest',
    MEMBER: 'Member',
    MEMBER_NO_MAIL_OPT_IN: 'MemberNoMailOptIn',
    MEMBER_NO_POST_OPT_IN: 'MemberNoPostOptIn',
    MEMBER_WITH_POST_OPT_IN: 'MemberWithPostOptIn',
    MEMBER_BONUSPOINT_RESET: 'MemberBonuspointsReset',
    MEMBER_NO_FAVORITE_STORE: 'MemberNoFavoriteStore',
    MEMBER_WITH_BONUS_MIGRATION: 'MemberWithBonusMigration',
    MEMBER_NO_BONUS_MIGRATION: 'MemberNoBonusMigration',
  },
  SESSION_TYPES: {
    SESSION_CUSTOMER: 'customer',
    SESSION_GUEST: 'guest',
  },
  STORYBLOK: {
    VISIBILITY_ALL: 'All' as CmsVisibility,
    VISIBILITY_HIDDEN: 'Hidden' as CmsVisibility,
    VISIBILITY_NONE: 'None' as CmsVisibility,
    STORY_PATHS: {
      COUNTRY_SELECTOR: 'shop/country-selector',
      COOKIE_BANNER: 'shop/cake-menu',
      SHOPPING_APPOINTMENT: 'shop/shopping-appointment',
      SHOP_ERROR: 'shop/error',
      CART: 'shop/cart',
      WISHLIST: 'shop/wishlist',
      SEARCH_NO_RESULT: 'shop/search-no-result',
      FOR_MEMBERS: 'shop/formembers',
      NEWSLETTER_RAFFLE: 'shop/newsletter/raffle',
      PDP_BRANDS: 'shop/pdp/pdp-brands',
      PDP_SUSTAINABILITY: 'shop/pdp/pdp-sustainability',
      STORE_RESERVATION: 'shop/store-reservation',
      ORDER_CONFIRMATION: 'shop/thank-you',
      ACCOUNT: 'shop/account',
      ACCOUNT_CONTACT_SETTINGS: 'shop/account/contact-settings',
      ACCOUNT_MEMBERS: 'shop/account/members',
      ACCOUNT_MEMBERS_NO_BONUS: 'shop/account/non-bonus-overlay',
      ACCOUNT_PAY_INVOICE: 'shop/account/pay-invoice-overlay',
      AUTH_WRAPPER: 'shop/auth/wrapper',
      AUTH_LOGIN: 'shop/auth/login',
      AUTH_REGISTER: 'shop/auth/register',
      AUTH_PASSWORD_RESET: 'shop/auth/password-reset',
      AUTH_PASSWORD_RESET_SUBMIT: 'shop/auth/password-reset-submit',
      ADD_TO_WALLET_OVERLAY: 'shop/account/add-to-wallet-overlay',
      WALLET_LANDING: 'shop/wallet-landing',
      ADD_TO_WALLET_TOAST: 'shop/account/add-to-wallet-toast',
      SIZE_TABLES: 'shop/pdp/size-tables',
      CONTACT: 'shop/contact',
      CHECKOUT: 'shop/checkout',
      CHECKOUT_GLOBAL_E: 'shop/checkout/global-e',
      SIZE_ADVICE_OVERLAY: 'shop/size-advice-overlay',
      RETURN_PORTAL: 'shop/return-portal',
    },
    GLOBAL_STORY_NAMES: {
      FOOTER: 'Footer',
      FLYOUT_SERVICE: 'Flyout Service',
      SHOP_PREFERENCES: 'Shop Preferences',
      SHOP_PREFERENCES_AB_TEST: 'Shop Preferences - AB Test',
      SHOP_PREFERENCES_CATEGORIES: 'Shop Preferences - Categories',
      SHOP_PREFERENCES_PRODUCTS: 'Shop Preferences - Products',
      SHOP_PREFERENCES_POPULARITY_FLAGS: 'Shop Preferences - Popularity Flags',
      SUPER_BANNER: 'Super Banner',
      MAINTENANCE: 'Maintenance',
      GUEST_BUYER_PAGE: 'Guest Buyer Page',
    },
  },
  LOCATION_FINDER: {
    CLOSED: 'closed',
  },
  STORE_FINDER: {
    LIST: 'list',
    MAP: 'map',
    STORE_CLOSED: 'closed',
  },
  NEWSLETTER_SOURCE: {
    ACCOUNT: 'MyAccount',
    FOOTER: 'Footer',
    RAFFLE: 'Raffle',
    REGISTRATION: 'Registration',
    ADVENT: 'Adventcalendar',
    PREFERENCECENTER: 'PreferenceCenter',
    BACK_IN_STOCK: 'BackInStock',
  },
  NEWSLETTER_CATEGORY: {
    SOI: 'newsletter_soi',
    DOI: 'newsletter_doi',
  },
  NEWSLETTER_TYPE: {
    RAFFLE: 'raffle',
    ADVENT: 'advent',
  },
  ROUTE: {
    NAMES: {
      CART: 'cart',
      CATEGORY_PAGE: 'category-page',
      CHECKOUT: {
        ROOT: 'checkout',
        ADDRESSES: 'checkout-addresses',
        BILLING: 'checkout-billing',
        SHIPPING: 'checkout-shipping',
        PAYMENT: 'checkout-payment',
        GLOBALE: 'checkout-global-e',
      },
    },
  },
  NEWSLETTER_GLOBAL_E_DISABLED_THEME_IDS: ['themeLoyalty'] as string[],
  DISALLOWED_ALTERNATE_SLUGS: [
    'ge-ge',
    'en-dk',
    'en-gr',
    'en-pt',
    'en-no',
    'en-is',
    'en-hr',
    'en-rs',
    'en-ae',
    'en-il',
    'en-qa',
    'en-in',
    'en-sg',
    'en-jp',
    'en-hk',
    'en-fi',
    'en-lu',
    'en-lv',
    'en-lt',
    'en-sk',
    'en-si',
    'en-ro',
    'en-hu',
    'en-ee',
    'en-it',
    'en-es',
  ] as string[],
  WALLET: {
    APPLE: 'Apple Wallet',
    GOOGLE: 'Google Wallet',
  },
  CHECKOUT: {
    ADDRESS_TYPE: {
      REAL_ADDRESS: 'realAddress',
      CLICK_AND_COLLECT: 'clickAndCollect',
      COLLECTION_POINT: 'collectionPoint',
    },
    REAL_ADDRESS_TYPE: {
      BILLING: 'billing',
      SHIPPING: 'shipping',
    },
  },
} as const;

export const OVERLAYS: any = {
  COOKIE: {
    URL_HASH: 'overlay-cookie',
  },
  COUNTRY: {
    URL_HASH: 'overlay-country',
  },
  SUPER_BANNER: {
    URL_HASH: 'overlay-superbanner',
  },
} as const;

export const WEEKDAYS: string[] = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
] as const;

export const URLS: { [key: string]: string } = {
  URL_PREFIX: '/([a-z]{2}-[a-z]{2})',
  LOGOUT: 'logout',
  REDIRECT_NOT_FOUND: 'redirect-not-found',
  ACCOUNT: 'account',
  GIFT_CARD: 'gift-card',
  ACCOUNT_ORDERS: 'account/orders',
  ACCOUNT_ORDER: 'account/order/',
  ACCOUNT_PROFILE: 'account/profile',
  ACCOUNT_CONTACT_SETTINGS: 'account/profile#contact',
  ACCOUNT_MEMBERS: 'account/members',
  ACCOUNT_ABOUT_BONUSPOINT: 'inspiration/memberry-bonuspoints',
  AUTH: 'auth',
  AUTH_LOGIN: 'auth/login',
  AUTH_REGISTER: 'auth/register',
  AUTH_PASSWORD_RESET: 'auth/password-reset',
  AUTH_PASSWORD_RESET_SUBMIT: 'auth/password-reset-submit',
  CONTACT: 'contact',
  SHIPMENT_TRACKING: 'shipment-tracking',
  SEARCH: 'search',
  STORE_FINDER: 'storefinder',
  SHOPPING_APPPOINTMENT: 'shopping-appointment',
  NEWSLETTER_INFORMATION: 'static/newsletter-information',
  NEWSLETTER_REGISTRATION: 'newsletter/registration',
  NEWSLETTER_CONFIRMATION: 'newsletter/confirmation',
  PREFERENCE_CENTER: 'newsletter/preference-center',
  CART: 'cart',
  WISHLIST: 'wishlist',
  CHECKOUT: 'checkout',
  CHECKOUT_SUCCESS: 'checkout/success',
  CHECKOUT_ADDRESSES: 'checkout/addresses',
  CHECKOUT_BILLING: 'checkout/billing',
  CHECKOUT_SHIPPING: 'checkout/shipping',
  CHECKOUT_PAYMENT: 'checkout/payment',
  CHECKOUT_GLOBAL_E: 'checkout/global-e',
  STORE_RESERVATION: 'store-reservation',
  MEMBERS: 'formembers',
  RETURN_PORTAL: 'return-portal',
  IMPRINT: 'static/imprint',
  PRIVACY: 'static/privacy',
  TERMS_AND_CONDITIONS: 'static/terms-and-conditions',
  FAQ: 'static/faq',
  FAQ_MEMBER: 'static/faq#member',
  FAQ_PAYMENT: 'static/faq#payment',
  FAQ_SHIPPING: 'static/faq#shipping',
  FAQ_SHIPPING_DELIVERY_TIMES: 'static/faq#shipping-3',
  WALLET_LANDING: 'wallet',
} as const;

export const EXTERNAL_LIBS: { [key: string]: string } = {
  APP_INSIGHTS: 'https://js.monitor.azure.com/scripts/b/ai.2.min.js',
  AB_TASTY: 'https://try.abtasty.com/{ID}.js',
  EPOQ_SNIPPET: 'https://cdn.epoq.de/flow/{TENANT_ID}.js',
} as const;

// more about image indexes: https://marc-o-polo.atlassian.net/wiki/spaces/DEV/pages/2467791055/Images+CP
export const PRODUCT_IMAGE_INDEX: { [key: string]: number } = {
  STANDARD: 1,
  BACK: 2,
  FULL: 3,
  DETAIL: 4,
  SIDE: 5,
  BUST: 7,
  SHOP_THE_LOOK: 8,
} as const;

export const PRODUCT_IMAGE_DIVISION: { [key: string]: number } = {
  DEFAULT: 1,
  LICENSE: 2,
} as const;

export const MOP_CATEGORY_IDS: { [key: string]: string } = {
  DENIM: 'mopd',
} as const;

export const WAREHOUSE_TYPES = ['ARVATO', 'CROSSDOCKING', 'SHIP_FROM_STORE'] as const;

export const FILTER_KEYS = [
  'size',
  'refinementColor',
  'mainMaterial',
  'armLength',
  'legLength',
  'fitting',
  'waistHeight',
  'brand',
  'design',
  'glassesShape',
];

export const SIZE_ORDERS = [
  {
    categories: [
      'women-clothing-coats',
      'women-clothing-jackets',
      'women-clothing-cardigans',
      'women-clothing-jumper',
      'women-clothing-sweat',
      'women-clothing-tshirts',
      'women-clothing-blouses',
      'women-clothing-polos',
      'women-clothing-blazer',
      'women-clothing-dresses',
      'women-clothing-jumpsuits',
      'women-clothing-vests',
    ],
    order: ['M', 'S', 'L', 'XL', 'XS', 'XXS', 'XXL', '38', '36', '40', '42', '44', '34', '32', 'M-L', 'XS-S'],
  },
  {
    categories: ['women-clothing-skirts', 'women-clothing-jeans', 'women-clothing-trousers', 'women-clothing-shorts'],
    order: [
      '38',
      '36',
      '40',
      '28',
      '29',
      '30',
      '27',
      '31',
      '26',
      '32',
      '33',
      '25',
      '34',
      'M',
      'S',
      'L',
      'XS',
      'XL',
      'XXS',
      'XXL',
      '29 / 32',
      '28 / 32',
      '30 / 32',
      '27 / 32',
      '31 / 32',
      '32 / 32',
      '26 / 32',
      '29 / 34',
      '28 / 30',
      '30 / 34',
      '29 / 30',
      '33 / 32',
      '28 / 34',
      '27 / 30',
      '31 / 34',
      '30 / 30',
      '27 / 34',
      '34 / 32',
      '26 / 30',
      '31 / 30',
      '32 / 34',
      '25 / 32',
      '32 / 30',
      '33 / 34',
      '34 / 34',
      '26 / 34',
      '25 / 30',
      '25 / 34',
    ],
  },
  {
    categories: ['women-shoes'],
    order: [
      '39',
      '38',
      '40',
      '37',
      '41',
      '42',
      '36',
      '6',
      '6,5',
      '5',
      '5,5',
      '4,5',
      '7',
      '7,5',
      '4',
      '8',
      '3,5',
      'M',
      'S',
      'L',
      'XL',
      '39/42',
      '35/38',
      '43/46',
    ],
  },
  {
    categories: [
      'men-clothing-coats',
      'men-clothing-jackets',
      'men-clothing-cardigans',
      'men-clothing-jumper',
      'men-clothing-shirts',
      'men-clothing-overshirts',
      'men-clothing-sweat',
      'men-clothing-tshirts',
      'men-clothing-polos',
      'men-clothing-sports-jackets',
      'men-clothing-vests',
    ],
    order: ['L', 'M', 'XL', 'XXL', 'S', '3XL', 'XS', '50', '52', '48', '54', '46', '56', '44', '58'],
  },
  {
    categories: ['men-clothing-jeans', 'men-clothing-trousers', 'men-clothing-shorts'],
    order: [
      '48',
      '50',
      '52',
      '54',
      '46',
      '56',
      '44',
      '34',
      '33',
      '32',
      '36',
      '31',
      '30',
      '38',
      '29',
      '40',
      '28',
      'L',
      'M',
      'XL',
      'S',
      'XXL',
      'XS',
      '32 / 32',
      '33 / 32',
      '34 / 32',
      '34 / 34',
      '31 / 32',
      '33 / 34',
      '32 / 34',
      '30 / 32',
      '36 / 34',
      '36 / 32',
      '31 / 34',
      '29 / 32',
      '38 / 34',
      '38 / 32',
      '30 / 34',
      '34 / 36',
      '33 / 36',
      '36 / 36',
      '29 / 34',
      '32 / 36',
      '40 / 32',
      '40 / 34',
      '28 / 32',
      '31 / 36',
      '32 / 30',
      '31 / 30',
      '28 / 34',
      '30 / 30',
      '33 / 30',
      '29 / 30',
      '34 / 30',
      '28 / 30',
      '36 / 30',
      '38 / 30',
      '40 / 30',
    ],
  },
  {
    categories: ['men-shoes'],
    order: ['43', '42', '44', '45', '41', '46', '40', '43-44', '41-42', '45-46', '39-40', '43/46', '39/42', '47/50'],
  },
];

export const PAYMENT_METHOD = {
  INVOICE: 'invoice',
  KLARNA: 'klarna',
  COD: 'cashondelivery',
  PAYPAL: 'paypal',
  CC: 'scheme',
  P24: 'onlineBanking_PL',
  IDEAL: 'ideal',
  GIFTCARD: 'giftcard',
} as const;

export const ERROR_TYPE = {
  MISSING_ASSET: 'Failed to fetch dynamically imported module',
} as const;

export const DATADOG_GROUP = {
  CHECKOUT: 'checkout',
} as const;

export const DATADOG_WHITELISTED_ERRORS = ['CHECKOUT_PAYMENT_IN_PROCESS'] as const;
