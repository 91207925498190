import { getMappedAddress, isValidAddress, isSameAddress } from '@/models/utils/addressUtils';
import type { ApiResponse } from '@/types/apiInit';
import type {
  BffApiAccountAddressGet,
  BffCartAddress,
  BffApiAddressCheckPost,
  BffApiAccountAddressAddressIdPut,
  BffApiAccountAddressPost,
} from '@/types/bff';

function getFormattedAddressFromAddressCheck(address?: BffCartAddress) {
  if (!address) {
    return;
  }
  return getMappedAddress({ ...address, id: 'formatted' });
}

export function addressListModel(responseData: ApiResponse<BffApiAccountAddressGet> | null) {
  return {
    getAddressModelList() {
      const validAddresses = responseData?.data?.addresses.filter((address) => isValidAddress(address)) ?? [];
      return validAddresses.map((address) => getMappedAddress(address) as BffCartAddress) ?? [];
    },
  };
}

export function addressUpdateModel(responseData: ApiResponse<BffApiAccountAddressAddressIdPut> | null) {
  const data = responseData?.data ?? ({} as BffApiAccountAddressAddressIdPut);

  return {
    getCheckResult() {
      return 'checkResult' in data ? data.checkResult : undefined;
    },
    getStatusCode() {
      return data.code;
    },
    getFormattedAddress() {
      return getFormattedAddressFromAddressCheck('formattedAddress' in data ? data.formattedAddress : undefined);
    },
    getUpdatedAddress() {
      return getMappedAddress('updatedAddress' in data ? data.updatedAddress : undefined);
    },
    getDebugData() {
      return responseData;
    },
  };
}

export function addressCreateModel(responseData: ApiResponse<BffApiAccountAddressPost> | null) {
  const data = responseData?.data ?? ({} as BffApiAccountAddressPost);

  return {
    getCheckResult() {
      return 'checkResult' in data ? data.checkResult : undefined;
    },
    getStatusCode() {
      return data.code;
    },
    getFormattedAddress() {
      return getFormattedAddressFromAddressCheck('formattedAddress' in data ? data.formattedAddress : undefined);
    },
    getCreatedAddress() {
      return getMappedAddress('createdAddress' in data ? data.createdAddress : undefined);
    },
    getDebugData() {
      return responseData;
    },
  };
}

export function addressRiskCheckModel(responseData: ApiResponse<BffApiAddressCheckPost> | null) {
  const data = responseData?.data ?? ({} as BffApiAddressCheckPost);

  return {
    getCheckResult() {
      return data && 'checkResult' in data ? data.checkResult : undefined;
    },
    getStatusCode() {
      return data.code;
    },
    getFormattedAddress() {
      return getFormattedAddressFromAddressCheck(
        data && 'formattedAddress' in data && data.formattedAddress ? data.formattedAddress : undefined,
      );
    },
    showSuggestion() {
      const actionCode = this.getCheckResult()?.actionCode;
      return this.getStatusCode() === 'ADDRESS_CHECK_FAILED' && (actionCode === 'Y_D' || actionCode === 'Y_I');
    },
    showUnknown() {
      const actionCode = this.getCheckResult()?.actionCode;
      return this.getStatusCode() === 'ADDRESS_CHECK_FAILED' && (actionCode === 'O_I' || actionCode === 'O_D');
    },
    getIsAcceptable(formAddress: BffCartAddress) {
      const statusCode = this.getStatusCode();
      const actionCode = this.getCheckResult()?.actionCode;
      const isAcceptableByCode =
        statusCode === 'OK' || (statusCode === 'ADDRESS_CHECK_FAILED' && (actionCode === 'E_A' || actionCode === 'R'));
      if (isAcceptableByCode) {
        return true;
      }
      // Visually the address does not differ from formated address -> lets consider as acceptable
      const hasSameAddressOnSuggestion =
        this.showSuggestion() && formAddress && isSameAddress(formAddress, this.getFormattedAddress());
      return hasSameAddressOnSuggestion;
    },
    getDebugData() {
      return responseData;
    },
  };
}
