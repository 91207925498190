<script lang="ts">
import { computed, ref, onMounted, onUnmounted, watch, nextTick } from 'vue';
import { onBeforeRouteLeave } from 'vue-router';
import type { PropType } from 'vue';
const types = ['modal', 'slideout'] as const;
type Type = (typeof types)[number];
</script>

<script setup lang="ts">
defineOptions({
  name: 'Ui2Overlay',
});
const emit = defineEmits(['update:modelValue', 'click:back']);

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  maxHeight: {
    type: String,
    default: '100dvh',
  },
  minHeight: {
    type: String,
    default: '200px',
  },
  width: {
    type: String,
    default: '480px',
  },
  maxWidth: {
    type: String,
    default: '780px',
  },
  persistent: {
    type: Boolean,
    default: false,
  },
  showCloseButton: {
    type: Boolean,
    default: false,
  },
  showBackButton: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  stickyFooter: {
    type: Boolean,
    default: false,
  },
  zIndex: {
    type: Number,
    default: 5,
  },
  attach: {
    type: String,
    default: 'body',
  },
  customClassName: {
    type: String,
    default: '',
  },
  type: {
    type: String as PropType<Type>,
    default: '',
    validator: (value: Type) => types.includes(value),
  },
  noPadding: {
    type: Boolean,
    default: false,
  },
  trackNavigation: {
    type: Boolean,
    default: false,
  },
});

const focusableElements = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
const isVisibleRef = computed(() => isRenderedInDomRef.value && props.modelValue);
const showBounceRef = ref(false);
let timer: any;
const NO_SCROLL_CLASSNAME = 'body--noscroll';
const overlayBodyRef = ref<HTMLElement>();
const firstFocusableElementRef = ref<HTMLElement>();
const lastFocusableElementRef = ref<HTMLElement>();
const transitionAnimation = String(props.type === 'modal' ? 'ui-overlay-reveal' : 'ui-overlay-slide-left');
let navigationBackUrl: string;

const isRenderedInDomRef = ref(false);
onMounted(() => {
  navigationBackUrl = window.history.state?.back;
  setTimeout(() => {
    isRenderedInDomRef.value = true;
  }, 100);
});

onBeforeRouteLeave((to, from, next) => {
  const isNavigatingBack = navigationBackUrl === to.fullPath;
  if (props.trackNavigation && isVisibleRef.value && isNavigatingBack) {
    handleCloseOverlay();
    return next(false);
  }

  return next();
});

function handleCloseOverlay(forceClose = false) {
  clearTimeout(timer);
  if (!props.persistent || forceClose) {
    return emit('update:modelValue', !isVisibleRef.value);
  }

  showBounceRef.value = true;
  timer = setTimeout(() => {
    showBounceRef.value = false;
  }, 100);
}

function handleBackClick() {
  emit('click:back');
}

function keyDownHandler(event: KeyboardEvent) {
  if (event.key === 'Escape') {
    return handleCloseOverlay();
  }
  const isTabPress = event.key === 'Tab';
  if (isTabPress && event.shiftKey) {
    if (isActiveElement(firstFocusableElementRef.value)) {
      lastFocusableElementRef.value!.focus();
      event.preventDefault();
    }
  } else if (isTabPress) {
    if (isActiveElement(lastFocusableElementRef.value)) {
      firstFocusableElementRef.value!.focus();
      event.preventDefault();
    }
  }
}

function isActiveElement(element?: HTMLElement) {
  if (!element) {
    return false;
  }
  return document.activeElement === element;
}

onUnmounted(destroy);

function destroy() {
  document.removeEventListener('keydown', keyDownHandler);
  document.body.classList.remove(NO_SCROLL_CLASSNAME);
}

watch(
  isVisibleRef,
  () => {
    destroy();
    if (!isVisibleRef.value) {
      return;
    }
    document.addEventListener('keydown', keyDownHandler);

    nextTick(() => {
      if (!overlayBodyRef.value) {
        return;
      }
      const scrollBarWidth = window.innerWidth - document.body.offsetWidth;
      document.body.style.setProperty('--sticky-scroll-bar-width', `${scrollBarWidth}px`);
      document.body.classList.add(NO_SCROLL_CLASSNAME);

      const focusableContentElements = Array.from(overlayBodyRef.value.querySelectorAll(focusableElements));
      focusableContentElements.unshift(overlayBodyRef.value);
      firstFocusableElementRef.value = focusableContentElements[0] as HTMLElement;
      lastFocusableElementRef.value = focusableContentElements[focusableContentElements.length - 1] as HTMLElement;
      firstFocusableElementRef.value?.focus();
    });
  },
  { immediate: true },
);
</script>

<template>
  <Teleport :to="props.attach">
    <Transition :name="`${transitionAnimation}-background`" @mousedown="() => handleCloseOverlay()">
      <div
        v-if="isVisibleRef"
        class="ui-overlay"
        :style="{
          '--zIndex': zIndex,
        }"
      />
    </Transition>
    <Transition :name="transitionAnimation">
      <div
        v-if="isVisibleRef"
        ref="overlayBodyRef"
        :class="['ui-overlay-body', `ui-overlay-body--${transitionAnimation}`, customClassName ? customClassName : '']"
        :aria-hidden="!isVisibleRef"
        role="dialog"
        aria-modal="true"
        tabindex="-1"
        :style="{
          '--maxHeight': maxHeight,
          '--minHeight': minHeight,
          '--width': width,
          '--maxWidth': maxWidth,
          '--zIndex': zIndex,
        }"
      >
        <div
          :class="[
            'ui-overlay-document',
            {
              'ui-overlay-document--bounce': showBounceRef,
              'ui-overlay-document--sticky-footer': stickyFooter,
              'ui-overlay-document--has-content': $slots.default || loading,
              'ui-overlay-document--no-padding': noPadding,
            },
          ]"
          :data-theme="$attrs['data-theme']"
        >
          <Ui2Button
            v-if="showBackButton"
            class="ui-overlay-document__back"
            type="close"
            icon-start="arrow-left"
            size="lg"
            @click="handleBackClick"
          />

          <Ui2Button
            v-if="showCloseButton"
            class="ui-overlay-document__close"
            type="close"
            size="lg"
            @click="() => handleCloseOverlay(true)"
          />

          <div class="ui-overlay-document__body">
            <div v-if="$slots.header" class="ui-overlay-document__header">
              <slot name="header" />
            </div>
            <div v-else-if="showCloseButton" class="ui-overlay-document__header">
              <Ui2OverlayHeader header="&nbsp;" type="centered" />
            </div>

            <template v-if="loading">
              <div
                v-if="$slots.default || (!stickyFooter && $slots.footer)"
                class="ui-overlay-document__content ui-overlay-document__content--loading"
              >
                <Ui2Loader type="normal" size="lg" />
                <div v-if="!stickyFooter && $slots.footer" class="ui-overlay-document__footer">
                  <slot name="footer" />
                </div>
              </div>
            </template>
            <template v-else>
              <div v-if="$slots.default || (!stickyFooter && $slots.footer)" class="ui-overlay-document__content">
                <slot v-if="$slots.default" name="default" />
                <div v-if="!stickyFooter && $slots.footer" class="ui-overlay-document__footer">
                  <slot name="footer" />
                </div>
              </div>
            </template>

            <div v-if="stickyFooter && $slots.footer" class="ui-overlay-document__footer">
              <slot name="footer" />
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<style lang="scss">
.body--noscroll {
  overflow: hidden;

  .lock-scroll-padding {
    padding-right: var(--sticky-scroll-bar-width) !important;
  }
}

.ui-overlay {
  z-index: var(--zIndex);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $color-surface-dimmer;
  opacity: 1;
  backdrop-filter: $blur-base;
}

.ui-overlay-body {
  position: fixed;
  z-index: var(--zIndex);
  width: var(--width);
  max-width: var(--maxWidth);
  outline: 0;

  @include v2-apply-upto(mobile) {
    width: 100%;
    max-width: 100%;
    padding: 0;
  }
}

.ui-overlay-document {
  height: 100%;
  width: 100%;
  background: $color-surface-primary;
  box-shadow: $shadow-2xl;
  position: relative;
}

.ui-overlay-body--ui-overlay-reveal {
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  padding: $space-48;

  .ui-overlay-document {
    border-radius: $border-radius-rounded-lg;

    @include v2-apply-upto(mobile) {
      border-radius: $border-radius-rounded-lg;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  @include v2-apply-upto(mobile) {
    left: 0;
    right: 0;
    top: unset;
    bottom: 0;
    transform: translate(0);
    padding: 0;
  }
}

.ui-overlay-body--ui-overlay-slide-left {
  top: 0;
  right: 0;

  .ui-overlay-document {
    border-radius: $border-radius-rounded-lg;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    @include v2-apply-upto(mobile) {
      border-radius: $border-radius-rounded-lg;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  @include v2-apply-upto(mobile) {
    left: 0;
    right: 0;
    top: unset;
    bottom: 0;
    transform: translate(0);
  }
}

.ui-overlay-document--bounce {
  scale: 105%;
}

.ui-overlay-document__back {
  position: absolute;
  left: $space-12;
  top: $space-12;
}

.ui-overlay-document__close {
  position: absolute;
  right: $space-12;
  top: $space-12;
}

.ui-overlay-document__body {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: var(--minHeight);
  max-height: var(--maxHeight);

  @include v2-apply-upto(mobile) {
    min-height: auto;
    max-height: calc(100vh - 48px);
    max-height: calc(100dvh - 48px);
  }
}

.ui-overlay-document__header {
  display: block;
  padding: 0 $space-24;
  border-bottom: 1px solid $color-border-secondary;

  @include v2-apply-upto(mobile) {
    padding: 0 $space-16;
  }
}

.ui-overlay-document__content {
  @include v2-text-style(sm);
  overscroll-behavior: contain;
  overflow-y: auto;
  padding: $space-24;
  flex-grow: 1;
  color: $color-text-body-primary;

  @include v2-apply-upto(mobile) {
    padding: $space-24 $space-16;
  }
}

.ui-overlay-document--no-padding .ui-overlay-document__content {
  padding: 0;
}

.ui-overlay-document__content--loading {
  justify-content: center;
  display: flex;
}

.ui-overlay-document__footer {
  padding: $space-16 0 0;

  @include v2-apply-upto(mobile) {
    padding: $space-16 0 0;
  }
}

.ui-overlay-document--sticky-footer.ui-overlay-document--has-content {
  .ui-overlay-document__footer {
    border-top: 1px solid $color-border-secondary;
  }
}
.ui-overlay-document--sticky-footer {
  .ui-overlay-document__body {
    padding: $space-24 0 $space-16;
    @include v2-apply-upto(mobile) {
      padding: $space-24 0 $space-16;
    }
  }

  .ui-overlay-document__footer {
    padding: $space-16 $space-24 0;

    @include v2-apply-upto(mobile) {
      padding: $space-16 $space-16 0;
    }
  }
}

.ui-overlay-reveal-enter-active {
  transition: opacity $animation-duration-x-long $animation-type-standard;
  .ui-overlay-document {
    transition: scale $animation-duration-x-long $animation-type-standard;
  }
}

.ui-overlay-reveal-leave-active {
  transition: opacity $animation-duration-long $animation-type-emphasized-accelerate;
  .ui-overlay-document {
    transition: scale $animation-duration-short $animation-type-standard;
  }
}

.ui-overlay-reveal-enter-from,
.ui-overlay-reveal-leave-to {
  .ui-overlay-document {
    scale: 0;
  }
  opacity: 0;

  @include v2-apply-upto(mobile) {
    opacity: 1;
    transform: translateY(100%);

    .ui-overlay-document {
      scale: 1;
    }
  }
}

.ui-overlay-reveal-background-enter-active {
  transition: opacity $animation-duration-short $animation-type-linear;
}

.ui-overlay-reveal-background-leave-active {
  transition: opacity $animation-duration-medium $animation-type-linear;
}

.ui-overlay-reveal-background-enter-from,
.ui-overlay-reveal-background-leave-to {
  opacity: 0;
}

.ui-overlay-slide-left-enter-active {
  transition: transform $animation-duration-x-long $animation-type-standard;
}

.ui-overlay-slide-left-leave-active {
  transition: transform $animation-duration-long $animation-type-emphasized-accelerate;
}

.ui-overlay-slide-left-enter-from,
.ui-overlay-slide-left-leave-to {
  transform: translateX(100%);

  @include v2-apply-upto(mobile) {
    transform: translateY(100%);
  }
}

.ui-overlay-slide-left-background-enter-active {
  transition: opacity $animation-duration-short $animation-type-linear;
}

.ui-overlay-slide-left-background-leave-active {
  transition: opacity $animation-duration-medium $animation-type-linear;
}

.ui-overlay-slide-left-background-enter-from,
.ui-overlay-slide-left-background-leave-to {
  opacity: 0;
}
</style>
